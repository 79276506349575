import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";

import { MapComponent, SidebarDevice, FooterComponent, FloatingSearch } from "../components";
import EntitiesStore from "../stores/EntitiesStore";
import AuthStore from "../stores/AuthStore";

type IProps = WithTranslation & {
    AuthStore: AuthStore
    EntitiesStore: EntitiesStore
};

@inject('EntitiesStore', 'AuthStore') @observer
class DashboardPage extends React.Component<IProps> {

    componentDidMount() {
        this.props.EntitiesStore.getEntities()
        if (this.props.AuthStore.user) {
            this.props.EntitiesStore.getMyEntities()
        }
    }

    render() {
        return (
            <div className='map-wrapper'>
                <MapComponent />
                <FloatingSearch />
                <SidebarDevice />
                <FooterComponent />
            </div>
        )
    }
}

export default withTranslation()(DashboardPage)