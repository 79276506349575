import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import { Card, PageHeader, Table, Tag, Button, Modal, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import DevicesStore from '../stores/DevicesStore';
import { Attribute } from '../models';
import { IDeviceType } from '../utils';
import { AddDeviceType } from '../components';
import AuthStore from '../stores/AuthStore';

const { info } = Modal;

type IProps = WithTranslation & {
  routerStore: RouterStore;
  DevicesStore: DevicesStore;
  AuthStore: AuthStore;
};

@inject('routerStore', 'DevicesStore', 'AuthStore')
@observer
class DeviceType extends React.Component<IProps> {
  async componentDidMount() {
    await this.props.DevicesStore.getDeviceTypesByUser();
  }

  handleAddEntityType = () => {
    const { DevicesStore, AuthStore, routerStore, t } = this.props;
    Modal.confirm({
      title: t('add_device_profile'),
      content: (
        <AddDeviceType
          DevicesStore={DevicesStore}
          AuthStore={AuthStore}
          routerStore={routerStore}
          closeModal={(getTypes?: boolean) => {
            if (getTypes) {
              this.props.DevicesStore.getDeviceTypesByUser();
            }
            Modal.destroyAll();
          }}
        />
      ),
      mask: true,
      maskClosable: true,
      width: '60vw',
      className: 'hide-modal-buttons',
    });
  };

  handleEditEntityType = (record: IDeviceType) => {
    const { DevicesStore, AuthStore, routerStore, t } = this.props;
    Modal.confirm({
      title: t('edit_device_profile'),
      content: (
        <AddDeviceType
          DevicesStore={DevicesStore}
          AuthStore={AuthStore}
          routerStore={routerStore}
          deviceType={record}
          isEdit={true}
          closeModal={(getTypes?: boolean) => {
            if (getTypes) {
              this.props.DevicesStore.getDeviceTypesByUser();
            }
            Modal.destroyAll();
          }}
        />
      ),
      mask: true,
      maskClosable: true,
      width: '60vw',
      className: 'hide-modal-buttons',
    });
  };

  /**
   * Delete device type
   *
   * @param record : Device type
   */
  handleDeleteEntityType = (record: IDeviceType) => {
    const { DevicesStore, t } = this.props;
    Modal.confirm({
      title: t('title_delete'),
      content: t('content_delete'),
      onOk() {
        // Call to device profile deletion
        DevicesStore.deleteDeviceType(record);
        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  handleShowEntity = async (record: any) => {
    console.log(record);
    const body = record.attributes.map((x: any) => {
      const number = (Math.random() * 10).toFixed(1);
      const constants = ['lat', 'lon', 'name', 'description', 'config', 'federate'];
      if (constants.includes(x.object_id)) return '';
      return `${x.object_id}|${number}|`;
    });
    info({
      width: '75%',
      title: record.entity_type,
      content: (
        <div>
          <hr></hr>
          <p>
            <b>ACTION POST</b>
          </p>
          <pre>
            <b>URL:</b>
            {`http://calidadmedioambiental.org/iot/d?k=${record.apikey}&i=<Device ID>`}
          </pre>
          <p>
            <b>HEADER:</b> "Content-Type":"text/plain"
          </p>
          <p>
            <b>BODY:</b>{' '}
            {body
              .toString()
              .replace(/,/g, '')
              .slice(0, -1)}
          </p>
        </div>
      ),
    });
  };

  privateAttributes = ['latitude', 'longitude', 'config', 'name', 'description', 'federate'];
  renderAttributes = (attributes: Array<Attribute>) =>
    attributes.map((attribute: any, index) => {
      if (this.privateAttributes.includes(attribute.name)) return '';
      return <Tag key={index.toString()}>{attribute.name}</Tag>;
    });
  render() {
    const { routerStore, t, DevicesStore } = this.props;
    const { deviceTypes, isLoading } = DevicesStore;

    const columns: Array<ColumnProps<IDeviceType>> = [
      {
        title: t('name'),
        dataIndex: 'entity_type',
        key: 'entity_type',
      },
      {
        title: t('apiKey'),
        dataIndex: 'apikey',
        key: 'apikey',
      },
      { title: t('service'), dataIndex: 'service', key: 'service' },
      { title: t('subservice'), dataIndex: 'subservice', key: 'subservice' },
      {
        title: t('reading'),
        dataIndex: 'attributes',
        key: 'attributes',
        render: (attributes: Array<Attribute>) => this.renderAttributes(attributes),
      },
      {
        title: t('actions'),
        key: 'action',
        render: (text, record) => (
          <span>
            <Tooltip title={t('show')}>
              <Button type="link" icon="eye" onClick={() => this.handleShowEntity(record)} />
            </Tooltip>
            <Tooltip title={t('delete')}>
              <Button type="link" icon="delete" onClick={() => this.handleDeleteEntityType(record)} />
            </Tooltip>
          </span>
        ),
      },
    ];

    const CardTitle = () => (
      <PageHeader
        style={{}}
        onBack={() => routerStore.goBack()}
        title={t('device_profiles')}
        extra={[
          <span style={{ paddingLeft: 10 }}>
            <Button key="add_device_profile" icon="plus" onClick={() => this.handleAddEntityType()}>
              {t('add_device_profile')}
            </Button>
          </span>,
        ]}
      />
    );

    return (
      <div>
        <Card headStyle={{ padding: 0 }} className="card-title-no-padding body-content" title={<CardTitle />}>
          <small>
            {t('my_profile_info_1')}
            <br />
            {t('my_profile_info_2')}
          </small>
          <Table
            loading={isLoading}
            rowKey={(row, index) => row._id || index.toString()}
            dataSource={deviceTypes}
            columns={columns}
          />
        </Card>
      </div>
    );
  }
}

export default withTranslation()(DeviceType);
