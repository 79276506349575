import React from 'react';
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import { COOKIE_TOKEN, COOKIE_PROFILE } from './utils/constants';

import {
  DashboardPage,
  ProfilePage,
  AddDevicePage,
  Endpoints,
  DeviceType,
  UsersManagement,
  LandingPage,
  MyDevices,
  EditEntityPage,
  AlertsConsole,
  AlertsManage,
  LegalNotice,
  ResetPassword,
  AemetEeaStations,
  InfoPage
} from './pages';
// import { EntityDetail } from "./pages"

import { Header } from './components';
import { User } from './models';

const { Content } = Layout;

interface IProps extends RouteComponentProps {}

const Router: React.FC<IProps> = () => {
  return (
    <Switch>
      <LandingRoute exact path='/' component={LandingPage} />
      <PublicRoute exact path='/legal-notice' component={LegalNotice} />
      <PublicRoute exact path='/reset-password' component={ResetPassword} />
      <PublicRoute exact path='/info' component={InfoPage} />
      <PublicRoute exact path='/dashboard' component={DashboardPage} />
      {/* <PublicRoute exact path="/:entityId" component={EntityDetail} /> */}
      <PrivateRoute exact path='/me/profile' component={ProfilePage} />
      <PrivateRoute exact path='/devices' component={MyDevices} />
      <PrivateRoute exact path='/devices/new' component={AddDevicePage} />
      <PrivateRoute exact path='/devices/edit/:entityId' component={EditEntityPage} />
      <PrivateRoute exact path='/devices/type' component={DeviceType} />
      <PrivateRoute exact path='/manage/endpoints' component={Endpoints} adminRequired={true} />
      <PrivateRoute exact path='/users' component={UsersManagement} adminRequired={true} />
      <PrivateRoute exact path='/aemet-eea' component={AemetEeaStations} adminRequired={true} />
      <PrivateRoute exact path='/alerts/console' component={AlertsConsole} />
      <PrivateRoute exact path='/alerts/manage' component={AlertsManage} />
    </Switch>
  );
};

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const userCookie: string | null = localStorage.getItem(COOKIE_PROFILE);
  let user: User | undefined;
  if (userCookie) user = JSON.parse(userCookie!);

  const RenderPrivateContent = (props: RouteComponentProps) => (
    <Layout className='layout'>
      <Header />
      <Content>
        <Component {...props} />
      </Content>
    </Layout>
  );

  const RenderRedirect = (props: RouteComponentProps) => (
    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  );

  return (
    <Route
      {...rest}
      render={props => {
        if (localStorage.getItem(COOKIE_TOKEN)) {
          if (rest.adminRequired) {
            if (user && user.role === 'Admin') return <RenderPrivateContent {...props} />;
            else return <RenderRedirect {...props} />;
          }
          return <RenderPrivateContent {...props} />;
        }
        return <RenderRedirect {...props} />;
      }}
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout className='layout'>
          <Header />
          <Content>
            <Component {...props} />
          </Content>
        </Layout>
      )}
    />
  );
};

const LandingRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout className='layout'>
          <Content>
            <Component {...props} />
          </Content>
        </Layout>
      )}
    />
  );
};

export default withRouter(Router);
