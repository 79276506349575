import React from 'react';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';

import Leaflet from 'leaflet';
import { Map, Marker, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Portal } from 'react-leaflet-portal';

import { LayersMap } from './LayersMap';

import EntitiesStore from '../stores/EntitiesStore';
import DashboardStore from '../stores/DashboardStore';
import AuthStore from '../stores/AuthStore';

import { Card, Tag, Popover, Switch } from 'antd';
import { getMarkerByDevice } from '../utils/LeafletMarkerConfig';
import { getNameById } from '../utils';
import { COLORS } from '../utils/constants';

type IProps = WithTranslation & {
  AuthStore?: AuthStore;
  EntitiesStore?: EntitiesStore;
  DashboardStore?: DashboardStore;
};

type IState = {
  zoom: number;
  stations: any;
};

@inject('EntitiesStore', 'DashboardStore', 'AuthStore')
@observer
class MapComponent extends React.Component<IProps, IState> {
  map: Map | undefined;

  state: IState = {
    zoom: 4,
    stations: {
      aemet: true,
      eea: true,
      respira: true,
      others: true,
      mines: true,
    },
  };

  handleFlyTo = (event: Leaflet.LeafletMouseEvent, device: any) => {
    this.props.DashboardStore!.map!.leafletElement.panTo(event.latlng);
    this.props.DashboardStore!.setshowSidebar(true);
    this.props.EntitiesStore!.getEntity(device.id);
  };

  parseDeviceId = (id: string) => {
    let array = id.split(':');
    return array[1];
  };

  onChangeStation = (checked: any, station: string) => {
    this.setState({
      stations: {
        ...this.state.stations,
        [station]: !this.state.stations[station],
      },
    });
  };

  render() {
    const { t, EntitiesStore } = this.props;
    const { zoom, stations } = this.state;
    const { user } = this.props.AuthStore!;

    const respiraDevices: any = stations.respira ? EntitiesStore!.respiraEntities : [];
    const aemetDevices: any = stations.aemet ? EntitiesStore!.aemetEntities : [];
    const eeaDevices: any = stations.eea ? EntitiesStore!.eeaEntities : [];
    const otherDevices: any = stations.others ? EntitiesStore!.otherEntities : [];
    const minesDevices: any = stations.mines ? EntitiesStore!.myEntities.docs : [];
    const myDevices: any = user ? minesDevices.map((e: any) => (e = { ...e, user: user!.fw_name })) : [];

    // if (user) removeFromArray(respiraDevices, myDevices);
    // if (user) removeFromArray(otherDevices, myDevices);
    // removeFromArray(aemetDevices, myDevices);
    // removeFromArray(eeaDevices, myDevices);

    const devices: Array<any> = respiraDevices.concat(otherDevices, aemetDevices, myDevices, eeaDevices);

    const content = (station: string) => (
      <div>
        <Switch
          checkedChildren={t('disable')}
          unCheckedChildren={t('enable')}
          checked={this.state.stations[station]}
          onChange={checked => this.onChangeStation(checked, station)}
        />
      </div>
    );

    return (
      <Map
        ref={(ref: any) => this.props.DashboardStore!.setMap(ref)}
        center={[40.4168, -3.7038]}
        zoom={zoom}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        bounds={[
          [37.964373, -7.317498],
          [39.332844, -5.279535],
        ]}
      >
        <Portal position="bottomleft" className="leaflet-bottom-panel">
          <div style={{ padding: '1em' }}>
            <Popover placement="topLeft" content={content('aemet')} title={t('legend_aemet_title')} trigger="click">
              <Tag color={stations.aemet ? COLORS.aemet : COLORS.disabled}>{t('legend_aemet')}</Tag>
            </Popover>
            <Popover placement="topLeft" content={content('eea')} title={t('legend_eea_title')} trigger="click">
              <Tag color={stations.eea ? COLORS.eea : COLORS.disabled}>{t('legend_eea')}</Tag>
            </Popover>
            <Popover placement="topLeft" content={content('respira')} title={t('legend_respira_title')} trigger="click">
              <Tag color={stations.respira ? COLORS.respira : COLORS.disabled}>{t('legend_respira')}</Tag>
            </Popover>
            <Popover placement="topLeft" content={content('others')} title={t('legend_others_title')} trigger="click">
              <Tag color={stations.others ? COLORS.others : COLORS.disabled}>{t('legend_others')}</Tag>
            </Popover>
            {user && (
              <Popover placement="topLeft" content={content('mines')} title={t('legend_mines_title')} trigger="click">
                <Tag color={stations.mines ? COLORS.mines : COLORS.disabled}>{t('legend_mines')}</Tag>
              </Popover>
            )}
          </div>
        </Portal>

        <LayersMap />
        <MarkerClusterGroup>
          {!EntitiesStore!.isLoading &&
            devices.map((device: any, index: number) => (
              <Marker
                onclick={ev => this.handleFlyTo(ev, device)}
                key={index}
                position={{
                  lng: device.longitude,
                  lat: device.latitude,
                }}
                icon={getMarkerByDevice(device)}
              >
                <Tooltip opacity={1} direction="top">
                  <Card
                    title={device.name ? device.name : t(getNameById(device.id))}
                    bordered={false}
                    size="small"
                    style={{ fontSize: '1em', width: 250 }}
                  >
                    {this.parseDeviceId(device.id)}
                  </Card>
                </Tooltip>
              </Marker>
            ))}
        </MarkerClusterGroup>
      </Map>
    );
  }
}

export default withTranslation()(MapComponent);
