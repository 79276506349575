import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ScatterChartComponent, LineChartComponent } from './';
import { Button, Checkbox } from 'antd';

type IProps = WithTranslation & {
  onClickChart(type: string): any;
  data: any;
  pol: string;
  lines: any;
};

type IState = {
  chartType: string;
  checked: any;
};

class ChartComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      chartType: 'dots',
      checked: [],
    };
  }

  onChangeLines = (checked: any) => {
    this.setState({ checked });
  };

  zoomChart = (type: string) => {
    this.props.onClickChart(type);
  };

  render() {
    const { chartType, checked } = this.state;
    const { t, pol, data } = this.props;

    const plainOptions = [t('minLine'), t('maxLine'), t('avgLine')];

    return (
      <div style={{ textAlign: 'center' }}>
        {chartType === 'dots' ? (
          <span>
            <ScatterChartComponent
              onChartClick={this.zoomChart}
              data={data}
              pol={pol}
              lines={checked}
            />
            <Button type="link" onClick={() => this.setState({ chartType: 'lines' })}>
              {t('lines_chart')}
            </Button>
            <Checkbox.Group options={plainOptions} value={checked} onChange={this.onChangeLines} />
          </span>
        ) : (
          <span>
            <LineChartComponent
              onChartClick={this.zoomChart}
              data={data}
              pol={pol}
              lines={checked}
            />
            <Button type="link" onClick={() => this.setState({ chartType: 'dots' })}>
              {t('dots_chart')}
            </Button>
            <Checkbox.Group options={plainOptions} value={checked} onChange={this.onChangeLines} />
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(ChartComponent);
