import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouterStore } from 'mobx-react-router';
import { inject, observer } from 'mobx-react';
import { Button, PageHeader, Card, Switch, Popconfirm, Badge } from 'antd';
import Table, { ColumnProps, PaginationConfig } from 'antd/lib/table';

import { User } from '../models';
import UsersStore from '../stores/UsersStore';
import { IPaginationParams } from '../utils';
import AuthStore from '../stores/AuthStore';

type IProps = WithTranslation & {
  routerStore: RouterStore;
  UsersStore: UsersStore;
  AuthStore: AuthStore;
};

@inject('routerStore', 'UsersStore', 'AuthStore')
@observer
class UsersManagement extends React.Component<IProps> {
  async componentDidMount() {
    await this.getUsers();
  }

  getUsers(options?: IPaginationParams) {
    return this.props.UsersStore.getUsers(options);
  }

  tableOnChange = async (pagination: PaginationConfig) => {
    await this.getUsers({ page: pagination.current, limit: pagination.pageSize });
  };

  handleEditUser = async (checked: boolean, user: User) => {
    await this.props.UsersStore.updateUser(
      { ...user, role: checked ? 'Admin' : 'Developer' },
      true,
    );
  };

  activateUser = async (user: User) => {
    await this.props.UsersStore.updateUser({ ...user, active: true }, true);
  };

  handleDeleteUser = async (userId: string) => {
    await this.props.UsersStore.deleteUser(userId, true);
  };

  render() {
    const { routerStore, t, AuthStore } = this.props;
    const { isLoading, users } = this.props.UsersStore;

    const columns: Array<ColumnProps<User>> = [
      { title: t('name'), dataIndex: 'name', key: 'name' },
      { title: t('surname'), dataIndex: 'surname', key: 'surname' },
      { title: t('email'), dataIndex: 'email', key: 'email' },
      { title: t('fwname'), dataIndex: 'fw_name', key: 'fw_name' },
      {
        title: t('role'),
        dataIndex: 'role',
        key: 'role',
        render: (text, record) => (
          <Switch
            checkedChildren={'Admin'}
            unCheckedChildren="Normal"
            checked={text === 'Admin'}
            onChange={checked => this.handleEditUser(checked, record)}
          />
        ),
      },
      {
        title: t('status'),
        dataIndex: 'active',
        key: 'active',
        render: (text, record) => (
          <span>
            <Badge
              status={text ? 'success' : 'error'}
              text={t(`${text ? 'enabled' : 'disabled'}`)}
            />
            <br />
            {!record.active && (
              <Button type="link" onClick={() => this.activateUser(record)}>
                {t('activate_user')}
              </Button>
            )}
          </span>
        ),
      },
      {
        title: t('actions'),
        key: 'actions',
        render: (text, record) => (
          <span>
            <Popconfirm
              title={t('popconfirm.title')}
              okText={t('popconfirm.yes')}
              cancelText={t('popconfirm.no')}
              onConfirm={() => this.handleDeleteUser(record._id!)}
              disabled={AuthStore.user!._id === record._id}
            >
              <Button type="link" icon="delete" disabled={AuthStore.user!._id === record._id} />
            </Popconfirm>
          </span>
        ),
      },
    ];

    const CardTitle = () => (
      <PageHeader style={{}} onBack={() => routerStore.goBack()} title={t('users')} />
    );

    return (
      <Card
        headStyle={{ padding: 0 }}
        className="body-content card-title-no-padding"
        title={<CardTitle />}
      >
        <Table
          loading={isLoading}
          rowKey={(row, index) => row._id || index.toString()}
          dataSource={users.docs}
          columns={columns}
          pagination={{
            size: 'small',
            hideOnSinglePage: true,
            current: users.page,
            pageSize: users.limit,
            total: users.totalDocs,
          }}
          onChange={this.tableOnChange}
        />
      </Card>
    );
  }
}

export default withTranslation()(UsersManagement);
