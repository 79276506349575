import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Map, Marker } from 'react-leaflet';
import { LatLngLiteral, LeafletMouseEvent, DragEndEvent, LeafletEvent, Icon } from 'leaflet';
import { InputNumber } from 'antd';

import { isNumber } from 'util';
import { LayersMap } from './LayersMap';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

type IProps = WithTranslation & {
  setFieldValue: (field: string, value: any) => void;
  position: LatLngLiteral | undefined;
};

type IState = {
  zoom: number;
};

class LocationMap extends React.Component<IProps, IState> {
  state: IState = { zoom: 5 };

  onChangePosition(key: 'lat' | 'lng', value: number | undefined) {
    if (isNumber(value)) {
      const position: any = { ...this.props.position, [key]: value };
      this.props.setFieldValue('location', position);
    }
  }

  render() {
    const { t, position } = this.props;
    const { zoom } = this.state;

    // Default coordinates for Merida (Badajoz)
    const DEFAULT_LATITUDE = 38.9233118;
    const DEFAULT_LONGITUDE = -6.3497424;

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div style={{ padding: '0 .5em .5em' }}>
            <label> {t('latitude')}: </label>
            <InputNumber
              value={position ? position.lat : DEFAULT_LATITUDE}
              precision={7}
              max={90}
              min={-90}
              onChange={(value: number | undefined) => this.onChangePosition('lat', value)}
            />
          </div>
          <div style={{ padding: '0 .5em .5em' }}>
            <label>{t('longitude')}: </label>
            <InputNumber
              value={position ? position.lng : DEFAULT_LONGITUDE}
              precision={7}
              max={180}
              min={-180}
              onChange={(value: number | undefined) => this.onChangePosition('lng', value)}
            />
          </div>
        </div>
        <Map
          center={[40.4168, -3.7038]}
          zoom={zoom}
          onzoomend={(event: LeafletEvent) => this.setState({ zoom: event.target._zoom })}
          onClick={(event: LeafletMouseEvent) => {
            if (!position) this.props.setFieldValue('location', event.latlng);
          }}
          attributionControl={true}
          zoomControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          easeLinearity={0.35}
          style={{ width: 'auto', height: '50vh' }}
        >
          <LayersMap />
          {position && (
            <Marker
              position={position}
              draggable={true}
              ondragend={(event: DragEndEvent) => this.props.setFieldValue('location', event.target._latlng)}
              icon={new Icon({ iconUrl: icon, shadowUrl: iconShadow })}
            />
          )}
        </Map>
      </div>
    );
  }
}

export default withTranslation()(LocationMap);
