import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getUnitByPollutant } from '../utils';
import _ from 'lodash';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  ScatterChart,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

type IProps = WithTranslation & {
  onChartClick(type: string): any;
  data: any;
  lines?: any;
  pol: string;
};

type IState = {
  checked: any;
};

class ScatterChartComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      checked: [],
    };
  }

  extractChart = () => {
    this.props.onChartClick('dots');
  };

  customLabel = (label: any) => {
    return moment
      .unix(label)
      .local()
      .format('HH:mm');
  };

  /**
   * Custom tooltip renderer
   */
  CustomTooltip = (data: any) => {
    if (!data.active) return null;

    return (
      <div className="chart-tooltip">
        <p className="chart-tooltip-top-label">
          {moment
            .unix(data.payload[0].value)
            .local()
            .format('DD/MM/YYYY HH:mm')}
        </p>
        <p className="chart-tooltip-bottom-label">{data.payload[1].value}</p>
      </div>
    );
  };

  render() {
    const { t, lines, data, pol } = this.props;
    const maxValue: any = _.maxBy(data, 'max');
    const minValue: any = _.minBy(data, 'min');

    const maxDate: any = _.maxBy(data, 'date');
    const minDate: any = _.minBy(data, 'date');

    const avg = _.meanBy(data, 'value');

    return (
      <ResponsiveContainer aspect={1.9}>
        <ScatterChart onClick={this.extractChart}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            name="Date"
            unit=""
            tickFormatter={label => this.customLabel(label)}
            scale="time"
            type="number"
            domain={[minDate.date, maxDate.date]}
          />
          <YAxis
            label={{ value: getUnitByPollutant(pol), angle: -90, position: 'insideLeft' }}
            dataKey="value"
            name="Value"
            unit=""
          />
          {lines && lines.find((e: any) => e === t('minLine')) && (
            <ReferenceLine y={minValue && minValue.min} stroke="green" />
          )}
          {lines && lines.find((e: any) => e === t('avgLine')) && <ReferenceLine y={avg} stroke="orange" />}
          {lines && lines.find((e: any) => e === t('maxLine')) && (
            <ReferenceLine y={maxValue && maxValue.max} stroke="red" />
          )}
          <Tooltip content={<this.CustomTooltip />} />
          <Scatter name="" data={data} fill="#82ca9d" />
        </ScatterChart>
      </ResponsiveContainer>
    );
  }
}

export default withTranslation()(ScatterChartComponent);
