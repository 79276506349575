import React from "react";

export const FooterComponent = () => (
    <footer className='transparent-footer'>
        <div className='wrapper' >
            <div className='badajoz' />
            <div className='telefonica' />
            <div className='fiware' />
            <div className='ps' />
        </div>
    </footer>
)