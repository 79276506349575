import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Tabs } from "antd";

import './index.scss';

import { LoginForm, SignUpForm } from "../../components";

type IProps = WithTranslation & {
    closeModal: () => void;
};

type IState = {
    activeTab: string
};


class LoginPage extends React.Component<IProps, IState> {

    state: IState = {
        activeTab: 'login'
    }

    onchange(key: string) {
        this.setState({ activeTab: key });
    }

    render() {
        const { t, closeModal} = this.props;
        const { activeTab } = this.state;

        return (
            <div id="login-page">
                <div className="login-wrap">
                    <div className='login-html'>
                        <Tabs activeKey={activeTab} onChange={(key) => this.onchange(key)} tabBarStyle={{ color: 'white' }}>
                            <Tabs.TabPane tab={t('login')} key="login">
                                <LoginForm closeModal={() => closeModal()}/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('signUp')} key="signUp">
                                <SignUpForm closeModal={() => closeModal()} onFinish={(key) => this.onchange(key)} />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(LoginPage);