import { observable, action } from 'mobx';
import axios from "axios";

import { COOKIE_PROFILE, COOKIE_TOKEN } from '../utils/constants';

import { setHeaderToken } from "../utils/axiosInterceptor";
import { User } from "../models";

export type ILoginForm = {
    email: string,
    password: string,
    rememberMe: boolean
}

export type IEmailForm = {
    email: string
}

export default class AuthStore {
    @observable loggedIn: boolean = false;
    @observable user?: User;
    @observable isLoading: boolean = true;
    @observable isModalLoginVisible: boolean = false;

    constructor() {
        const userCookie: string | null = localStorage.getItem(COOKIE_PROFILE);
        if (userCookie && localStorage.getItem(COOKIE_TOKEN)) {
            this.loggedIn = true;
            this.user = JSON.parse(userCookie);
        } else {
            this.loggedIn = false;
            this.user = undefined;
        }
    }

    @action changeModalLoginVisible(status: boolean) {
        this.isModalLoginVisible = status;
    }

    @action async login(user: ILoginForm): Promise<User> {
        try {
            const result: { user: User, token: string } = await axios.post(`/auth`, user);
            this.setUserAuth(result.user)
            localStorage.setItem(COOKIE_TOKEN, result.token);
            setHeaderToken();
            this.changeModalLoginVisible(false)
            return user;
        } catch (error) {
            return Promise.reject(error)
        }
    }

    @action setUserAuth = (user: User) => {
        this.loggedIn = true;
        this.user = user;
        localStorage.setItem(COOKIE_PROFILE, JSON.stringify(user))
    }

    @action logout = () => {
        this.loggedIn = false;
        this.user = undefined;
        localStorage.removeItem(COOKIE_PROFILE);
        localStorage.removeItem(COOKIE_TOKEN);
    }

    @action getMe = async (): Promise<User> => {
        try {
            this.isLoading = true;
            const user: User = await axios.get('/users/me')
            this.setUserAuth(user);
            this.isLoading = false;
            return user;
        } catch (error) {
            this.isLoading = false;
            return Promise.reject(error)
        }
    }

    @action editMe = async (data: User): Promise<User> => {
        try {
            this.isLoading = true;
            const userEdited: User = await axios.put('/users/me', data);
            this.setUserAuth(userEdited);
            this.isLoading = false;
            return userEdited;
        } catch (error) {
            this.isLoading = false;
            return Promise.reject(error)
        }
    }

    changePassword(password: string): Promise<User> {
        return axios.put('/users/me/password', { password })
    }

    /**
     * Request user password recovery
     * 
     * @param email: user e-mail address where to send recovery link to
     * 
     * @return response from server
     */
    @action requestPasswordReset(email: string) {
        return axios.put('/users/reset-password', { email })
    }

    @action deleteAccount() {
        this.isLoading = true;
        return axios.delete('/users/me')
            .then(this.logout)
            .finally(() => this.isLoading = false)
    }

}