import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form as AntForm, Input, Button, Row, Col, message, Card, PageHeader, Switch } from 'antd';
import { Formik, Form, Field, FormikActions, FieldProps } from 'formik';
import * as yup from 'yup';
import { RouterStore } from 'mobx-react-router';
import { inject, observer } from 'mobx-react';

import LocationMap from '../components/LocationMap';
import { InputError } from '../components';

import AuthStore from '../stores/AuthStore';
import EntitiesStore from '../stores/EntitiesStore';
import { IEntityEdition } from '../models';

import { RouteComponentProps } from 'react-router';

import moment from 'moment';

type IProps = RouteComponentProps<{ entityId: string }> &
  WithTranslation & {
    routerStore: RouterStore;
    AuthStore: AuthStore;
    EntitiesStore: EntitiesStore;
  };

type Istate = {
  entityId: string;
  entity: IEntityEdition;
};

@inject('routerStore', 'AuthStore', 'EntitiesStore')
@observer
class EditEntityPage extends React.Component<IProps, Istate> {
  state: Istate = {
    entityId: this.props.match.params.entityId,
    entity: {},
  };

  /**
   * Load entity information by user and id
   */
  async componentDidMount() {
    const { EntitiesStore, AuthStore } = this.props;
    //await EntitiesStore!.getEntity(this.state.entityId);
    await EntitiesStore!.getEntityByUserAndId(this.state.entityId, AuthStore!.user!.fw_name);

    this.getLocation();
    console.log('Federation = ' + this.state.entity.federate);
  }

  getLocation() {
    const { selectedEntity } = this.props.EntitiesStore;
    let latitude = parseFloat(selectedEntity.latitude) || 0;
    let longitude = parseFloat(selectedEntity.longitude) || 0;

    // Default coordinates for Merida (Badajoz)
    const DEFAULT_LATITUDE = 38.9233118;
    const DEFAULT_LONGITUDE = -6.3497424;

    if (latitude === 0) latitude = DEFAULT_LATITUDE;
    if (longitude === 0) longitude = DEFAULT_LONGITUDE;

    this.setState({ entity: { ...selectedEntity, location: { lat: latitude, lng: longitude } } });
  }

  /**
   * Handler for the federation switch
   */
  handleFederationChange = async (checked: boolean) => {
    const { selectedEntity } = this.props.EntitiesStore;

    let latitude = parseFloat(selectedEntity.latitude) || 0;
    let longitude = parseFloat(selectedEntity.longitude) || 0;

    this.setState({ entity: { ...selectedEntity, federate: checked, location: { lat: latitude, lng: longitude } } });
  };

  onSubmit = async (values: IEntityEdition, actions: FormikActions<IEntityEdition>) => {
    actions.setSubmitting(true);

    let entityId: any = values.id;

    let attributes: any = {
      name: values.name,
      description: values.description,
      latitude: values.location!.lat,
      longitude: values.location!.lng,
      federate: this.state.entity.federate,
    };

    try {
      await this.props.EntitiesStore.updateEntityAttributes(entityId, attributes);
      message.success(this.props.t('success.edited_device'));
      this.props.routerStore.replace('/devices');
    } catch (error) {
      message.error(this.props.t('errors.edited_device'));
    }
    actions.setSubmitting(false);
  };

  render() {
    const { t, routerStore } = this.props;
    const { entity } = this.state;

    return (
      <Card
        className="body-content"
        title={<PageHeader onBack={() => routerStore.goBack()} title={t('edit_device')} style={{ padding: 0 }} />}
      >
        <Formik
          enableReinitialize
          initialValues={entity}
          onSubmit={this.onSubmit}
          validationSchema={yup.object().shape({
            name: yup.string().required(t('validation_scheme.required_entity_name')),
          })}
          render={({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <Row gutter={24}>
                <Col xs={24} sm={12}>
                  <AntForm.Item label={t('entity_name')} required>
                    <Field
                      name="name"
                      render={({ field }: FieldProps) => <Input {...field} placeholder={t('entity_name')} />}
                    />
                    <InputError title="name" />
                  </AntForm.Item>
                  <AntForm.Item label={t('entity_description')}>
                    <Field
                      name="description"
                      render={({ field }: FieldProps) => <Input {...field} placeholder={t('weather_station')} />}
                    />
                  </AntForm.Item>
                  <AntForm.Item label={t('device_profile')}>
                    <Field render={() => <label>{this.props.EntitiesStore.selectedEntity.type}</label>} />
                  </AntForm.Item>
                  <AntForm.Item label={t('last_update')}>
                    <Field
                      render={() => (
                        <label>
                          {moment
                            .utc(this.props.EntitiesStore.selectedEntity.TimeInstant, 'YYYY-MM-DDThh:mm:ssZ')
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss')}
                        </label>
                      )}
                    />
                  </AntForm.Item>
                  <AntForm.Item label={t('federate_device')}>
                    <Field
                      name="federate"
                      render={() => (
                        <Switch
                          checkedChildren="on"
                          unCheckedChildren="off"
                          checked={(entity.federate === true) ? true : false}
                          onChange={checked => this.handleFederationChange(checked)}
                        />
                      )}
                    />
                  </AntForm.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <AntForm.Item label={t('add_location_title')}>
                    <LocationMap position={values.location} setFieldValue={setFieldValue} />
                  </AntForm.Item>
                </Col>
                <div className="text-center">
                  <Button onClick={() => routerStore.goBack()}>{t('cancel')}</Button>
                  <Button
                    type="primary"
                    style={{ margin: '0 1em' }}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    htmlType="submit"
                  >
                    {t('save')}
                  </Button>
                </div>
              </Row>
            </Form>
          )}
        />
      </Card>
    );
  }
}

export default withTranslation()<IProps>(EditEntityPage);
