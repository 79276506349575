import { observable, action, computed } from 'mobx';
import axios from 'axios';
import { IQueryEntityParams } from '../utils';

// import { Entity } from '../models';
type IEntitesPaginated = {
  docs: Array<any>;
  limit: number;
  offset: number;
  total: number;
  page: number;
  pages: number;
};
export default class EntitiesStore {
  @observable isLoading: boolean = false;
  @observable entities: Array<any> = [];
  @observable selectedEntity: any = {};
  @observable entitiesWithLocation: any = [];
  @observable entitiesByUser: any = [];
  @observable allEntities: any = [];
  @observable attributes: any = [];
  @observable myEntities: IEntitesPaginated = {
    docs: [],
    limit: 0,
    offset: 0,
    total: 0,
    page: 0,
    pages: 0,
  };

  @action async getEntities(params?: IQueryEntityParams) {
    this.isLoading = true;

    try {
      this.entities = await axios.get('/entities', { params });
      this.entitiesWithLocation = this.entities.filter((entity: any) => entity.latitude && entity.longitude);
      this.isLoading = false;
      return this.entities;
    } catch (error) {
      this.isLoading = false;
      this.entitiesWithLocation = [];
      return Promise.reject(error);
    }
  }

  getDeviceByID(id: string, user: string) {
    return axios.get(`/devices/iot/${id}/${user}`);
  }

  getServiceGroup(type: string, user: string) {
    return axios.get(`/devices/iot/servicegroup/${type}/${user}`);
  }

  @action async getEntity(entityId: string) {
    this.isLoading = true;

    try {
      this.selectedEntity = {};
      this.selectedEntity = await axios.get(`/entities/${entityId}`);
      if (
        !this.selectedEntity.location &&
        typeof this.selectedEntity.latitude === 'number' &&
        typeof this.selectedEntity.longitude === 'number'
      ) {
        let location: any = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.selectedEntity.latitude || 0}&lon=${this
            .selectedEntity.longitude || 0}`,
        );

        this.selectedEntity.location = location.display_name
          ? location.display_name
          : location.address.county || 'No location';
      } else this.selectedEntity.location = 'No location';
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.selectedEntity = {};
      return Promise.reject(error);
    }
  }

  /**
   * Get entity by ID and user. Store entity in selectedEntity
   * 
   * @param id : entity id
   * @param user : user id
   */
    @action async getEntityByUserAndId(entityId: string, userId: any) {
    this.isLoading = true;

    try {
      this.selectedEntity = {};
      this.selectedEntity = await axios.get(`/entities/user/myEntities/${entityId}/${userId}`);
      if (
        !this.selectedEntity.location &&
        typeof this.selectedEntity.latitude === 'number' &&
        typeof this.selectedEntity.longitude === 'number'
      ) {
        let location: any = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.selectedEntity.latitude || 0}&lon=${this
            .selectedEntity.longitude || 0}`,
        );

        this.selectedEntity.location = location.display_name
          ? location.display_name
          : location.address.county || 'No location';
      } else this.selectedEntity.location = 'No location';
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.selectedEntity = {};
      return Promise.reject(error);
    }
  }

  @computed get respiraEntities() {
    return this.entitiesWithLocation.filter((entity: any) => entity.type.includes('RESPIRA'));
  }

  @computed get aemetEntities() {
    return this.entitiesWithLocation.filter((entity: any) => entity.type.includes('AEMET'));
  }

  @computed get eeaEntities() {
    return this.entitiesWithLocation.filter((entity: any) => entity.type.includes('EEA'));
  }

  @computed get otherEntities() {
    return this.entitiesWithLocation.filter(
      (entity: any) =>
        entity.type.indexOf('RESPIRA') === -1 &&
        entity.type.indexOf('AEMET') === -1 &&
        entity.type.indexOf('EEA') === -1,
    );
  }

  @action async getMyEntities(params?: IQueryEntityParams) {
    this.isLoading = true;
    try {
      this.myEntities = await axios.get('/entities/user/myEntities', { params });
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action async getEntitiesByUser(user: any) {
    this.isLoading = true;
    try {
      this.entitiesByUser = await axios.get(`/entities/user/myEntities/${user}`);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action async getEntitiesAll() {
    this.isLoading = true;
    try {
      this.allEntities = await axios.get('/entities/all');
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action async getAttributes(id: any, user: any) {
    this.isLoading = true;
    try {
      this.attributes = await axios.get(`/entities/user/myEntities/${id}/${user}`);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action async deleteEntity(entityId: string, user: string) {
    this.isLoading = true;
    try {
      await axios.delete(`/entities/${entityId}/${user}`);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  updateEntityAttributes(entityId: string, attributes: any, params?: { options: 'keyValues' }) {
    return axios.put(`/devices/${entityId}/attrs`, attributes, { params });
  }

  /**
   * Retrieve federation ID for a given entity
   * 
   * @param id : entity id
   * @param user : user id or service path
   * 
   * @return federation ID
   */
  @action async getFederationId(id: string, user: string) {
    this.isLoading = true;
    const federDescr = `FEDERATION:${user}:${id}`
    try {
      const result: any = await axios.get(`/federations/${federDescr}`);
      this.isLoading = false;
      if ('id' in result)
        return result.id;
    } catch (error) {
      this.isLoading = false;
      return null;
    }
  }

  /**
   * Update federation for a given entity
   * 
   * @param id : entity id
   * @param user : user id or service path
   * @param state : new federation state
   */
  @action async updateFederation(id: string, user: string, state: boolean) {
    this.isLoading = true;

    const body: any = {
      userId: user,
      id: id,
      state: state
    }

    try {
      await axios.put('/federations', body);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }
}
