import React from 'react';
import { Layout, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import AuthStore from '../stores/AuthStore';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Login } from '../pages';


import MenuHeader from './MenuHeader';


type IProps = WithTranslation & {
    AuthStore?: AuthStore;
}

@inject('AuthStore')
@observer
class Header extends React.Component<IProps> {


    isModalVisible(status: boolean) {
        this.props.AuthStore!.changeModalLoginVisible(status)
    }

    render() {

        return (
            <Layout.Header style={{ padding: 0 }} className="header" >
                <Link to='/dashboard' className='header-logo'>
                    {/* <span style={{ color: 'white' }}>Respira</span> */}
                </Link>
                <MenuHeader />
                <Modal visible={this.props.AuthStore!.isModalLoginVisible} footer={null} bodyStyle={{ padding: 0 }}
                    onCancel={() => this.isModalVisible(false)} destroyOnClose>
                    <Login closeModal={() => this.isModalVisible(false)}/>
                </Modal>
            </Layout.Header>
        )
    }
}

export default withTranslation()(Header)