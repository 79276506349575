import { observable, action } from 'mobx';
import axios from 'axios';

import { IPaginationProps, IPaginationParams } from '../utils';
import { Alert } from '../models';

export default class AlertsStore {
  @observable isLoading: boolean = false;
  @observable alerts: IPaginationProps<Alert> = { docs: [] };
  @observable configuredAlerts: Array<any> = [];

  @action async getAlerts(user: any, params?: IPaginationParams) {
    this.isLoading = true;
    try {
      const _alerts: any = await axios.get(`/notifications/${user}`);
      this.alerts = { docs: _alerts };
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  /**
   * Remove all alerts generated for a given user
   *
   * @param user : user (fiware ID string) owner of the alerts to be cleaned
   */
  @action async cleanAlerts(user: any) {
    this.isLoading = true;
    try {
      await axios.delete(`/notifications/${user}`);
      this.alerts = { docs: [] };
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action async updateAlert(alert: Alert, reload: boolean = false, user: string) {
    this.isLoading = true;
    this.getAlerts(user);
    this.isLoading = false;
  }

  @action async getConfiguredAlerts(user: string, reload: boolean = false) {
    this.isLoading = true;
    try {
      this.configuredAlerts = await axios.get(`/alert/${user}`);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.configuredAlerts = [];
      return Promise.reject(error);
    }
  }

  @action async createAlert(alert: any) {
    this.isLoading = true;
    try {
      const result = await axios.post(`/alert`, alert);
      this.configuredAlerts.push(result);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action async switchAlert(id: String) {
    this.isLoading = true;
    try {
      await axios.get(`/alert/switch/${id}`);
      const foundIndex = this.configuredAlerts.findIndex(x => x._id === id);
      this.configuredAlerts[foundIndex].active = !this.configuredAlerts[foundIndex].active;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action async deleteAlert(id: String) {
    this.isLoading = true;
    try {
      await axios.delete(`/alert/${id}`);
      const foundIndex = this.configuredAlerts.findIndex(x => x._id === id);
      this.configuredAlerts.splice(foundIndex, 1);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }
}
