import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouterStore } from 'mobx-react-router';
import { inject, observer } from 'mobx-react';
import { Card, PageHeader, Button, Tooltip, Modal, Col, Row } from 'antd';
import Table, { ColumnProps, PaginationConfig } from 'antd/lib/table';
import { Map, Marker } from 'react-leaflet';
import { LayersMap } from '../components/LayersMap';
import Leaflet from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Clipboard from 'react-clipboard.js';

import { Alert } from '../models';
import AlertsStore from '../stores/AlertsStore';
import { IPaginationParams } from '../utils';
import AuthStore from '../stores/AuthStore';
import DashboardStore from '../stores/DashboardStore';
import EntitiesStore from '../stores/EntitiesStore';

type IProps = WithTranslation & {
  routerStore: RouterStore;
  AlertsStore: AlertsStore;
  AuthStore: AuthStore;
  DashboardStore: DashboardStore;
  EntitiesStore: EntitiesStore;
};

@inject('routerStore', 'AlertsStore', 'AuthStore', 'DashboardStore', 'EntitiesStore')
@observer
class AlertsConsole extends React.Component<IProps> {
  async componentDidMount() {
    await this.getAlerts();
  }

  getAlerts(options?: IPaginationParams) {
    const { user } = this.props.AuthStore;
    return this.props.AlertsStore.getAlerts(user!.fw_name, options);
  }

  tableOnChange = async (pagination: PaginationConfig) => {
    await this.getAlerts({ page: pagination.current, limit: pagination.pageSize });
  };

  handleNavigation = () => {
    this.props.routerStore.replace('/dashboard');
  };

  /**
   * Remove all alerts from panel after confirming from modal
   */
  handleCleanAlerts = () => {
    const { t } = this.props;
    const { user } = this.props.AuthStore;
    Modal.confirm({
      title: t('title_delete'),
      content: t('content_delete'),
      onOk: () => {
        this.props.AlertsStore.cleanAlerts(user!.fw_name);
        Modal.destroyAll();
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  handleShowAlert = async (record: any) => {
    const { t } = this.props;
    await this.props.EntitiesStore!.getEntity(record.id);
    let device: any = await this.props.EntitiesStore!.selectedEntity;
    const { latitude, longitude } = device;

    Modal.info({
      width: '75%',
      title: record.id,
      content: (
        <Row gutter={24}>
          <Col xs={24} sm={4}>
            <h1>
              {t('value')}: <strong>{record.value}</strong>
            </h1>
          </Col>
          <Col xs={24} sm={20}>
            <Map
              center={[latitude, longitude]}
              zoom={50}
              attributionControl={true}
              zoomControl={true}
              doubleClickZoom={true}
              scrollWheelZoom={true}
              dragging={false}
              animate={true}
              easeLinearity={0.35}
              style={{ width: 'auto', height: '50vh' }}
            >
              <LayersMap />
              <Marker
                position={[latitude, longitude]}
                draggable={false}
                icon={new Leaflet.Icon({ iconUrl: icon, shadowUrl: iconShadow })}
              />
            </Map>
            <Clipboard data-clipboard-text={record!.id} className='apiButton' onSuccess={() => this.handleNavigation()}>
              {t('copy_navigate')}
            </Clipboard>
          </Col>
        </Row>
      ),
    });
  };

  handleClick = async (record: any) => {
    await this.props.EntitiesStore!.getEntity(record.id);
    let device = await this.props.EntitiesStore!.selectedEntity;
    let latlng = Leaflet.latLng(device.latitude || 0, device.longitude || 0);
    this.props.DashboardStore!.map!.leafletElement.flyTo(latlng, 14);
    this.props.routerStore.replace('/dashboard');
  };

  render() {
    const { routerStore, t } = this.props;
    const { isLoading, alerts } = this.props.AlertsStore;

    const columns: Array<ColumnProps<Alert>> = [
      { title: t('name'), dataIndex: 'id', key: 'id' },
      { title: t('endpoint'), dataIndex: 'target', key: 'target' },
      {
        title: t('operator'),
        dataIndex: 'operator',
        key: 'operator',
        render: (text, record) => t(text),
      },
      { title: t('targetValue'), dataIndex: 'targetValue', key: 'targetValue' },
      { title: t('value'), dataIndex: 'value', key: 'value' },
      { title: t('detail.date'), dataIndex: 'createdAt', key: 'createdAt' },
      {
        title: t('actions'),
        key: 'action',
        render: (text, record) => (
          <span>
            <Tooltip title={t('show')}>
              <Button type='link' icon='eye' onClick={() => this.handleShowAlert(record)} />
            </Tooltip>
          </span>
        ),
      },
    ];

    const CardTitle = () => (
      <PageHeader
        style={{}}
        onBack={() => routerStore.goBack()}
        title={t('alerts')}
        extra={[
          <Button key='clean_alerts' icon='delete' onClick={() => this.handleCleanAlerts()}>
            {t('clean_alerts')}
          </Button>,
        ]}
      />
    );

    return (
      <Card headStyle={{ padding: 0 }} className='body-content card-title-no-padding' title={<CardTitle />}>
        <Table
          loading={isLoading}
          rowKey={(row, index) => row._id || index.toString()}
          dataSource={alerts.docs}
          columns={columns}
          pagination={{
            size: 'small',
            hideOnSinglePage: true,
            current: alerts.page,
            pageSize: alerts.limit,
            total: alerts.totalDocs,
          }}
          onChange={this.tableOnChange}
        />
      </Card>
    );
  }
}

export default withTranslation()(AlertsConsole);
