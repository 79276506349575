const env: boolean = process.env.NODE_ENV === 'development';

export const baseURL = env ? 'http://localhost:9000/api/v1' : '/api/v1/';

export const COOKIE_PROFILE = 'org.respira.user';
export const COOKIE_TOKEN = 'org.respira.token';

export const LNG = 0,
  LAT = 1;

export const COLORS: any = {
  others: '#772de0',
  eea: '#3865D6',
  respira: '#0d670c',
  aemet: '#4fa7f5',
  mines: '#bd72cc',
  disabled: '#575B5F',
};

export type IEndpointsDescription = {
  ultralight: string;
  fiware: string;
  description: {
    [key: string]: string;
    en: string;
    es: string;
  };
  unit?: string;
  unitDescription?: {
    [key: string]: string;
    en: string;
    es: string;
  };
};

export const ENDPOINTS_DESCRIPTION: Array<IEndpointsDescription> = [
  {
    ultralight: 'lat',
    fiware: 'latitude',
    description: {
      en: 'Latitude',
      es: 'Latitud',
    },
    unit: '°',
    unitDescription: {
      en: 'degrees',
      es: 'grados',
    },
  },
  {
    ultralight: 'lon',
    fiware: 'longitude',
    description: {
      en: 'Longitude',
      es: 'Longitud',
    },
    unit: '°',
    unitDescription: {
      en: 'degrees',
      es: 'grados',
    },
  },
  {
    ultralight: 'alt',
    fiware: 'altitude',
    description: {
      en: 'Altitude',
      es: 'Altitud',
    },
    unit: 'm',
    unitDescription: {
      en: 'meters',
      es: 'metros',
    },
  },
  {
    ultralight: 'co',
    fiware: 'CO',
    description: {
      en: 'CO',
      es: 'CO',
    },
    unit: 'μg/m³',
  },
  {
    ultralight: 'so2',
    fiware: 'SO2',
    description: {
      en: 'SO2',
      es: 'SO2',
    },
    unit: 'μg/m³',
  },
  {
    ultralight: 'no',
    fiware: 'NO',
    description: {
      en: 'NO',
      es: 'NO',
    },
    unit: 'μg/m³',
  },
  {
    ultralight: 'no2',
    fiware: 'NO2',
    description: {
      en: 'NO2',
      es: 'NO2',
    },
    unit: 'μg/m³',
  },
  {
    ultralight: 'o3',
    fiware: 'O3',
    description: {
      en: 'O3',
      es: 'O3',
    },
    unit: 'μg/m³',
  },
  {
    ultralight: 'pm1',
    fiware: 'PM1.0',
    description: {
      en: 'PM1.0',
      es: 'PM1.0',
    },
    unit: 'μg/m³',
  },
  {
    ultralight: 'pm2',
    fiware: 'PM2.5',
    description: {
      en: 'PM2.5',
      es: 'PM2.5',
    },
    unit: 'μg/m³',
  },
  {
    ultralight: 'pm4',
    fiware: 'PM4.0',
    description: {
      en: 'PM4.0',
      es: 'PM4.0',
    },
    unit: 'μg/m³',
  },
  {
    ultralight: 'pm10',
    fiware: 'PM10',
    description: {
      en: 'PM10',
      es: 'PM10',
    },
    unit: 'μg/m³',
  },
  {
    ultralight: 'typs',
    fiware: 'typicalSize',
    description: {
      en: 'Typical particulate size',
      es: 'Tamaño típico de partí­cula',
    },
    unit: 'um',
  },
  {
    ultralight: 't',
    fiware: 'temperature',
    description: {
      en: 'Temperature',
      es: 'Temperatura',
    },
    unit: '°C',
    unitDescription: {
      en: 'celsius degrees',
      es: 'grados celsius',
    },
  },
  {
    ultralight: 'h',
    fiware: 'humidity',
    description: {
      en: 'Relative humidity',
      es: 'Humedad relativa',
    },
    unit: '%',
  },
  {
    ultralight: 'p',
    fiware: 'pressure',
    description: {
      en: 'Barometric pressure',
      es: 'Presión barométrica',
    },
    unit: 'hPa',
  },
  {
    ultralight: 'tmin',
    fiware: 'minTemperature',
    description: {
      en: 'Minimum temperature',
      es: 'Temperatura mí­nima',
    },
    unit: '°C',
    unitDescription: {
      en: 'celsius degrees',
      es: 'grados celsius',
    },
  },
  {
    ultralight: 'tmax',
    fiware: 'maxTemperature',
    description: {
      en: 'Maximum temperature',
      es: 'Temperatura máxima',
    },
    unit: '°C',
    unitDescription: {
      en: 'celsius degrees',
      es: 'grados celsius',
    },
  },
  {
    ultralight: 'st',
    fiware: 'soilTemperature',
    description: {
      en: 'Soil temperature',
      es: 'Temperatura del suelo',
    },
    unit: '°C',
    unitDescription: {
      en: 'celsius degrees',
      es: 'grados celsius',
    },
  },
  {
    ultralight: 'prec',
    fiware: 'precipitation',
    description: {
      en: 'Precipitation',
      es: 'Precipitación',
    },
    unit: 'l/m²',
  },
  {
    ultralight: 'liqp',
    fiware: 'liquidPrecipitation',
    description: {
      en: 'Liquid precipitation',
      es: 'Precipitación lí­quida',
    },
    unit: 'l/m²',
  },
  {
    ultralight: 'solp',
    fiware: 'solidPrecipitation',
    description: {
      en: 'Solid precipitation',
      es: 'Precipitación sólida',
    },
    unit: 'l/m²',
  },
  {
    ultralight: 'inso',
    fiware: 'insolation',
    description: {
      en: 'Insolation',
      es: 'Insolación',
    },
    unit: 'lux',
  },
  {
    ultralight: 'vis',
    fiware: 'visibility',
    description: {
      en: 'Visibility',
      es: 'Visibilidad',
    },
    unit: 'm',
    unitDescription: {
      en: 'meters',
      es: 'metros',
    },
  },
  {
    ultralight: 'dp',
    fiware: 'dewPoint',
    description: {
      en: 'Dew point',
      es: 'Punto de rocí­o',
    },
    unit: '°C',
    unitDescription: {
      en: 'celsius degrees',
      es: 'grados celsius',
    },
  },
  {
    ultralight: 'wdmax',
    fiware: 'maxWindDirection',
    description: {
      en: 'Maximum wind direction',
      es: 'Dirección del viento máxima',
    },
    unit: '°',
    unitDescription: {
      en: 'degrees',
      es: 'grados',
    },
  },
  {
    ultralight: 'wd',
    fiware: 'windDirection',
    description: {
      en: 'Wind direction',
      es: 'Dirección del viento',
    },
    unit: '°',
    unitDescription: {
      en: 'degrees',
      es: 'grados',
    },
  },
  {
    ultralight: 'ws',
    fiware: 'windSpeed',
    description: {
      en: 'Wind speed',
      es: 'Velocidad del viento',
    },
    unit: 'm/s',
  },
  {
    ultralight: 'wsmax',
    fiware: 'maxWindSpeed',
    description: {
      en: 'Maximum wind speed',
      es: 'Velocidad del viento máxima',
    },
    unit: 'm/s',
  },
  {
    ultralight: 'ph',
    fiware: 'pH',
    description: {
      en: 'pH',
      es: 'pH',
    },
  },
  {
    ultralight: 'q',
    fiware: 'airQualityIndex',
    description: {
      en: 'Air quality index',
      es: 'Índice calidad del aire',
    },
  },
];
