import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Card, PageHeader } from "antd";
import { RouterStore } from "mobx-react-router";
import { inject, observer } from "mobx-react";

import queryString from 'query-string';


type IProps = WithTranslation & {
    routerStore: RouterStore
}

@inject('routerStore')
@observer
class InfoPage extends React.Component<IProps> {

    render() {
        const { t } = this.props;
        const params = queryString.parse(this.props.routerStore.location.search);        

        return (
            <Card className='body-content' title={
                <PageHeader title={t(params.title!)} style={{ padding: 0 }} />
            }>
                <h1>{t(params.body!)}</h1>
            </Card>
        )
    }
}

export default withTranslation()<IProps>(InfoPage)
