import { observable, action } from 'mobx';
import { Map } from 'react-leaflet';

export default class DashboardStore {
  @observable showSidebar: boolean = false;
  @observable isLoading: boolean = false;
  @observable map?: Map;

  @action setshowSidebar = (status: boolean) => {
    this.showSidebar = status;
  };

  @action setMap = (map: any) => {
    this.map = map;
  };
}
