import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import { Button, message, Form as AntForm, Input, Select } from 'antd';
import { Formik, Form, Field, FormikActions, FieldProps } from 'formik';
import * as yup from 'yup';

import AlertsStore from '../stores/AlertsStore';
import DevicesStore from '../stores/DevicesStore';
import AuthStore from '../stores/AuthStore';
import EntitiesStore from '../stores/EntitiesStore';
import { InputError } from '.';

type IProps = WithTranslation & {
  AlertsStore: AlertsStore;
  AuthStore: AuthStore;
  DevicesStore: DevicesStore;
  EntitiesStore: EntitiesStore;
  closeModal: (getDevice?: boolean) => void;
  routerStore: RouterStore;
  isEdit?: boolean;
};

type InitialValues = {
  id?: string;
  target?: string;
  operator?: string;
  value?: string;
};

type IDevices = { count: number; devices: [] };

type IState = {
  attributes: [];
  initialValues: InitialValues;
};

@inject('AlertsStore', 'AuthStore', 'DevicesStore', 'EntitiesStore')
@observer
class AddAlert extends React.Component<IProps, IState> {
  state: IState = {
    attributes: [],
    initialValues: {
      id: '',
      target: '',
      operator: '',
      value: '',
    },
  };

  componentDidMount() {
    this.getEntities();
  }

  getEntities = async () => {
    //const { user } = this.props.AuthStore!;
    //await this.props.EntitiesStore.getEntitiesByUser(user!.fw_name);
    //await this.props.EntitiesStore.getEntitiesAll();
    await this.props.EntitiesStore.getEntities();
  };

  renderSelectOption = (devices: any) => {
    // const { t, i18n } = this.props;
    let name: string | undefined = devices.name ? devices.name : devices.id;
    return <Select.Option key={devices.id}>{`${name}`}</Select.Option>;
  };

  renderSelectEndpointOption = (endpoint: any) => {
    // const { t, i18n } = this.props;
    // const deletedProperty: Array<any> = [
    //   'id',
    //   'latitude',
    //   'longitude',
    //   'name',
    //   'description',
    //   'config',
    //   'metadata',
    //   'TimeInstant',
    //   'type',
    // ];
    const selects = Object.keys(endpoint).map((x: any) => {
      return <Select.Option key={x}>{`${x}`}</Select.Option>;
    });
    return selects;
  };

  handleChange = (event: any) => {
    const e = event.target.value.replace(/\D/, '');
    this.setState(prevState => ({
      initialValues: {
        ...prevState.initialValues,
        value: e,
      },
    }));
  };

  handleAttributes = async (id: string) => {
    const { user } = this.props.AuthStore!;
    const { getAttributes } = this.props.EntitiesStore!;
    await getAttributes(id, user!.fw_name);
  };

  onSubmit = async (values: InitialValues, formikActions: FormikActions<InitialValues>) => {
    const { user } = this.props.AuthStore;
    formikActions.setSubmitting(true);
    try {
      const alert = { ...values, ...{ user: user!.fw_name } };
      await this.props.AlertsStore.createAlert(alert);
      this.props.closeModal(true);
    } catch (error) {
      message.error(this.props.t(`errors.${this.props.isEdit ? 'edit_device_profile' : 'add_device_profile'}`));
    }
  };

  render() {
    const { initialValues } = this.state;
    const { entities } = this.props.EntitiesStore;
    const { t, isEdit } = this.props;
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validationSchema={yup.object().shape({
            id: yup.string().required(t('validation_scheme.required_name')),
            target: yup.string().required(t('validation_scheme.required_name')),
            operator: yup.string().required(t('validation_scheme.required_name')),
            value: yup.string().required(t('validation_scheme.required_name')),
          })}
          render={({ isSubmitting, setFieldValue }) => (
            <Form>
              <AntForm.Item label={t('device')} required>
                <Field
                  name='id'
                  render={({ field }: FieldProps) => (
                    <Select
                      showSearch
                      {...field}
                      dropdownStyle={{ zIndex: 1700 }}
                      optionFilterProp='children'
                      filterOption={(input, option: any) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value: string) => {
                        setFieldValue('id', value);
                        this.setState(prevState => ({
                          initialValues: {
                            ...prevState.initialValues,
                            id: value,
                          },
                        }));
                      }}
                      placeholder={t('device')}
                    >
                      {entities.map(this.renderSelectOption)}                      
                    </Select>
                  )}
                />
                <InputError title='device' />
              </AntForm.Item>
              <AntForm.Item label={t('endpoint')} required>
                <Field
                  name='target'
                  render={({ field }: FieldProps) => (
                    <Select
                      {...field}
                      dropdownStyle={{ zIndex: 1700 }}
                      onChange={(value: string) => {
                        setFieldValue('target', value);
                        this.setState(prevState => ({
                          initialValues: {
                            ...prevState.initialValues,
                            target: value,
                          },
                        }));
                      }}
                      placeholder={t('endpoint')}
                    >
                      {this.state.initialValues.id ? (
                        entities
                          .filter((item: any) => item.id === this.state.initialValues.id)
                          .map(this.renderSelectEndpointOption)
                      ) : (
                        <Select.Option key='Others'>Others</Select.Option>
                      )}
                    </Select>
                  )}
                />
                <InputError title='endpoint' />
              </AntForm.Item>
              <AntForm.Item label={t('operator')} required>
                <Field
                  name='operator'
                  render={({ field }: FieldProps) => (
                    <Select
                      {...field}
                      dropdownStyle={{ zIndex: 1700 }}
                      onChange={(value: any) => {
                        setFieldValue('operator', value);
                        this.setState(prevState => ({
                          initialValues: {
                            ...prevState.initialValues,
                            operator: value,
                          },
                        }));
                      }}
                      placeholder={t('operator')}
                    >
                      <Select.Option key='gte'>{t('gte')}</Select.Option>
                      <Select.Option key='lte'>{t('lte')}</Select.Option>
                    </Select>
                  )}
                />
                <InputError title='operator' />
              </AntForm.Item>
              <AntForm.Item label={t('value')} required>
                <Field
                  name='value'
                  render={({ field }: FieldProps) => (
                    <Input
                      {...field}
                      disabled={isEdit}
                      value={this.state.initialValues.value}
                      onChange={event => this.handleChange(event)}
                      placeholder={t('value')}
                    />
                  )}
                />
                <InputError title='value' />
              </AntForm.Item>
              <AntForm.Item>
                <Button onClick={() => this.props.closeModal(true)}>{t('cancel')}</Button>
                <Button
                  type='primary'
                  style={{ margin: '0 1em' }}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  htmlType='submit'
                >
                  {t('save')}
                </Button>
              </AntForm.Item>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default withTranslation()<IProps>(AddAlert);
