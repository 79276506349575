import React from 'react';
import { DatePicker, Select, Empty, Modal } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import DataStore from '../stores/DataStore';
import { ExportCSV } from '../components/ExportToCSV';
import { ChartComponent } from '.';
import { getNameById } from '../utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

type IProps = WithTranslation & {
  DataStore?: DataStore;
  deviceId: string;
  pollutant: string;
};

type IState = {
  start: string;
  modal: boolean;
  checked: any;
  chartType: string;
};

@inject('DataStore')
@observer
class ChartContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      start: '24',
      modal: false,
      chartType: 'dots',
      checked: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.DataStore!.getDataChart(this.props.deviceId, this.props.pollutant, this.state.start);
  }

  onChangeLines = (checked: any) => {
    this.setState({ checked });
  };

  onChangeDate = (date: any, dateString: any) => {
    this.props.DataStore!.getDataChart(this.props.deviceId, this.props.pollutant, dateString[0], dateString[1]);
  };

  handleChangePeriod = async (value: any) => {
    this.setState({ start: value }, () => this.getData());
  };

  handleModal = () => this.setState({ modal: true });

  getAllData = async () => {
    await this.props.DataStore!.getAllDataFromChart(this.props.deviceId, this.props.pollutant);
    this.setState({ modal: true });
  };

  render() {
    const { start, checked, modal } = this.state;
    const { t, pollutant, deviceId } = this.props;
    const { dataChart } = this.props.DataStore!;

    return (
      <div style={{ textAlign: 'center' }}>
        {pollutant !== 'config' && pollutant !== 'federate' && pollutant !== 'AQI' && pollutant !== undefined && (
          <div style={{ textAlign: 'center', paddingBottom: '1em' }}>
            <RangePicker onChange={this.onChangeDate} />
            <Select
              defaultValue={start}
              placeholder={t('select_period')}
              style={{ paddingTop: '.5em', width: 160 }}
              onChange={this.handleChangePeriod}
            >
              <Option value="24">{t('24')}</Option>
              <Option value="week">{t('week')}</Option>
              <Option value="month">{t('month')}</Option>
              <Option value="year">{t('year')}</Option>
            </Select>
          </div>
        )}
        {dataChart.length > 0 ? (
          <ChartComponent onClickChart={this.handleModal} data={dataChart} pol={pollutant} lines={checked} />
        ) : (
          <Empty />
        )}
        <ExportCSV csvData={dataChart} />
        <Modal
          title={`${getNameById(deviceId)} - ${deviceId}`}
          width={800}
          visible={modal}
          footer={null}
          onCancel={() => {
            this.setState({ modal: false, checked: [] });
          }}
        >
          {dataChart.length > 0 ? (
            <ChartComponent onClickChart={this.handleModal} data={dataChart} pol={pollutant} lines={checked} />
          ) : (
            <Empty />
          )}
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(ChartContainer);
