import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Form as form, Input, Button, message } from "antd";
import { Formik, Form, Field, FormikActions, FieldProps } from 'formik';
import * as yup from 'yup';

import { InputError } from "../../components";
import { User } from "../../models";


type IProps = WithTranslation & {
    onCancel: () => void,
    changePassword: (password: string) => Promise<User>
}

type InitValues = {
    password: string;
    repeat_password: string;
}

class ChangePasswordForm extends React.Component<IProps> {

    onSubmit = async (values: InitValues, formikActions: FormikActions<InitValues>) => {
        formikActions.setSubmitting(true);
        try {
            await this.props.changePassword(values.password);
            message.success(this.props.t('success.reset_password'))
            this.props.onCancel();
        } catch (error) {
            message.error(this.props.t('errors.reset_password'))
        }
        formikActions.setSubmitting(false);
    }

    render() {
        const { t, onCancel } = this.props;

        return (
            <Formik enableReinitialize
                initialValues={{ password: '', repeat_password: '' }}
                onSubmit={this.onSubmit}
                validationSchema={yup.object().shape({
                    password: yup.string().required(t('validation_scheme.password')),
                    repeat_password: yup.string()
                        .oneOf([yup.ref('password')], t('validation_scheme.passwords_not_match'))
                        .required(t('validation_scheme.password')),
                })}
                render={({ isSubmitting }) => (
                    <Form>
                        <form.Item label={t('password')}>
                            <Field name="password" render={({ field }: FieldProps) => (
                                <Input {...field} placeholder={t('password')} type={'password'} autoComplete='password' />
                            )} />
                            <InputError title='password' />
                        </form.Item>
                        <form.Item label={t('repeat_password')}>
                            <Field name="repeat_password" render={({ field }: FieldProps) => (
                                <Input {...field} placeholder={t('repeat_password')} type={'password'} autoComplete='password' />
                            )} />
                            <InputError title='repeat_password' />
                        </form.Item>
                        <div className="text-center">
                            <Button onClick={() => onCancel()} style={{ marginRight: '1em' }}>{t('go_back')}</Button>
                            <Button type="primary" disabled={isSubmitting} loading={isSubmitting} htmlType="submit">{t('reset_password')}</Button>
                        </div>
                    </ Form>
                )}
            />)
    }
}

export default withTranslation()<IProps>(ChangePasswordForm)
