import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import { Button, PageHeader, Card, Modal, Tooltip } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';

import EntitiesStore from '../stores/EntitiesStore';
import AuthStore from '../stores/AuthStore';

const { confirm, destroyAll, info } = Modal;
type IProps = WithTranslation & {
  routerStore: RouterStore;
  EntitiesStore: EntitiesStore;
  AuthStore: AuthStore;
};

@inject('routerStore', 'EntitiesStore', 'AuthStore')
@observer
class MyDevices extends Component<IProps> {
  async componentDidMount() {
    this.getMyEntities();
  }

  getMyEntities = async () => {
    return this.props.EntitiesStore.getMyEntities();
  };

  getDeviceById = async (id: string, user: string) => {
    return this.props.EntitiesStore.getDeviceByID(id, user);
  };

  getServiceGroup = async (type: string, user: string) => {
    return this.props.EntitiesStore.getServiceGroup(type, user);
  };

  handleShowEntity = async (record: any) => {
    const { AuthStore } = this.props;
    const user: any = AuthStore!.user;
    const device: any = await this.getDeviceById(record.id.split(':')[1], user.fw_name);
    const services: any = await this.getServiceGroup(device.entity_type, user.fw_name);
    let service: any = {};
    await services.services.forEach((item: any) => {
      if (item.entity_type === device.entity_type) service = item;
    });
    const body = service.attributes.map((x: any) => {
      const number = (Math.random() * 10).toFixed(1);
      const constants = ['lat', 'lon', 'name', 'description', 'config', 'federate', 'location'];
      if (constants.includes(x.object_id)) return '';
      return `${x.object_id}|${number}|`;
    });
    info({
      width: '75%',
      title: record.name ? record.name : record.id,
      content: (
        <div>
          <hr></hr>
          <p>
            <b>ACTION POST</b>
          </p>
          <pre>
            <b>URL:</b>
            {`http://calidadmedioambiental.org/iot/d?k=${service.apikey}&i=${record.id}`}
          </pre>
          <p>
            <b>HEADER:</b> "Content-Type":"text/plain"
          </p>
          <p>
            <b>BODY:</b>{' '}
            {body
              .toString()
              .replace(/,/g, '')
              .slice(0, -1)}
          </p>
        </div>
      ),
    });
  };

  handleDeleteEntity = (record: string) => {
    const { t, EntitiesStore, AuthStore } = this.props;
    let user: any = AuthStore!.user;
    confirm({
      title: t('title_delete'),
      content: t('content_delete'),
      onOk() {
        EntitiesStore!.deleteEntity(record, user.fw_name);
        EntitiesStore.getMyEntities();
        destroyAll();
      },
      onCancel() {
        destroyAll();
      },
    });
  };

  render() {
    const { t, routerStore } = this.props;
    const { isLoading, myEntities } = this.props.EntitiesStore;

    const columns: Array<ColumnProps<any>> = [
      {
        title: t('id'),
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('description'),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: t('type'),
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: t('actions'),
        key: 'action',
        render: (text, record) => (
          <span>
            <Tooltip title={t('show')}>
              <Button type="link" icon="eye" onClick={() => this.handleShowEntity(record)} />
            </Tooltip>
            <Tooltip title={t('edit')}>
              <Button type="link" icon="edit" onClick={() => routerStore.push(`/devices/edit/${record.id}`)} />
            </Tooltip>
            <Tooltip title={t('delete')}>
              <Button type="link" icon="delete" onClick={() => this.handleDeleteEntity(record.id)} />
            </Tooltip>
          </span>
        ),
      },
    ];

    const CardTitle = () => (
      <PageHeader
        style={{}}
        onBack={() => routerStore.goBack()}
        title={t('my_devices')}
        extra={[
          t('my_devices_info'),
          // //   <Button key="add_device" icon="plus" onClick={() => routerStore.push('/devices/new')}>
          // //     {t('add_device')}
          // //   </Button>,
        ]}
      />
    );

    return (
      <Card headStyle={{ padding: 0 }} className="body-content card-title-no-padding" title={<CardTitle />}>
        <Table
          loading={isLoading}
          rowKey={(row, index) => row._id || index.toString()}
          dataSource={myEntities.docs}
          columns={columns}
        />
      </Card>
    );
  }
}

export default withTranslation()(MyDevices);
