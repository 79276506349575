import React from 'react'
import { Button, Col } from 'antd'
import { Trans } from "react-i18next";
import { ExportToCsv } from 'export-to-csv';

export const ExportCSV = ({ csvData, fileName }: { csvData: any, fileName?: string }) => {

    const exportToCSV = (csvData: any, fileName?: string) => {
        const options = {
            fieldSeparator: ',',
            filename: fileName ? fileName : 'Data',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(csvData);
    }

    return (
        <Col span={24}>
            <Button style={{ margin: '.5em' }} onClick={(e) => exportToCSV(csvData, fileName)}><Trans i18nKey="exportCSV"></Trans></Button>
        </Col>
    )
}