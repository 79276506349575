import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Card, PageHeader } from "antd";
import { RouterStore } from "mobx-react-router";
import { inject, observer } from "mobx-react";


type IProps = WithTranslation & {
    routerStore: RouterStore
}

@inject('routerStore')
@observer
class LegalNotice extends React.Component<IProps> {

    render() {
        const { t } = this.props;

        return (
            <Card className='body-content' title={
                <PageHeader title={t('legal_notice')} style={{ padding: 0 }} />
            }>

                <h1>Términos y condiciones de uso del sitio web</h1>
                <p>
                    Por favor, lea atentamente esta política y asegúrese de que la entiende y está de acuerdo con ella antes de facilitarnos sus datos personales.
                    Si no está de acuerdo con la misma, no utilice este sitio web o sus servicios, ni nos facilite sus datos.
                </p>
                <p>
                    El hecho de acceder a este sitio, usar cualquiera de sus servicios o facilitarnos sus datos, bien sea online u offline, lo entenderemos como 
                    una clara acción afirmativa por la que nos da su consentimiento (cuando sea necesario el mismo) para tratar sus datos para las finalidades que 
                    luego se indican.
                </p>
                <p>
                    Por ello, si las consideraciones detalladas en este Aviso Legal no son de su conformidad, rogamos no haga uso de la plataforma, ya que cualquier 
                    uso que haga del mismo o de los servicios y contenidos en él incluidos implicará la aceptación de los términos legales recogidos en este texto. 
                    Las condiciones y términos que se recogen en el presente Aviso pueden variar, por lo que le invitamos a que revise estos términos cuando visite 
                    de nuevo el portal.
                </p>
                <p>
                    El uso de este sitio web implica la expresa y plena aceptación, de las presentes condiciones generales en la versión publicada en el momento en 
                    que el usuario acceda al mismo, sin perjuicio de las condiciones particulares que pudieran aplicarse a algunos de los contenidos o servicios del 
                    sitio web. La Diputación Provincial de Badajoz procura que esta información sea exacta y precisa, y procede a su actualización con la máxima celeridad 
                    posible, tratando de evitar errores y corrigiéndolos tan pronto como los detecta. No obstante, la Diputación Provincial de Badajoz no puede garantizar 
                    la inexistencia de errores ni que el contenido de la información se encuentre permanentemente actualizado. Le sugerimos que compruebe siempre la 
                    vigencia y exactitud de la información, servicios y contenidos recogidos en la misma.
                </p>
                <p>
                    La información obtenida a través de este sitio web cumple una función de información general en los términos establecidos en el artículo 4 del Real Decreto 
                    208/1996, por lo que en ningún caso podrá derivarse efecto jurídico vinculante alguno.
                </p>
                <p>
                    Las informaciones de carácter medioambiental pretenden simplemente ser orientativas y carecen del rigor necesario para ser tenidas en cuenta con fines 
                    científicos o medioambientales. Esta plataforma aspira simplemente a convertirse en un contenedor de datos medioambientales gestionados desde la ciudadanía 
                    y a servir de plataforma de promoción de actividades de recogida de datos IoT medioambientales.
                </p>
                <p>
                    Con el fin de mejorar las prestaciones del sitio web, la Diputación de Badajoz, se reserva el derecho, en cualquier momento y sin previa notificación a los 
                    usuarios, a modificar, ampliar o suspender temporalmente la presentación, configuración, especificaciones técnicas y servicios del sitio web.
                </p>
                <p>
                    Los derechos de propiedad intelectual de este Portal de Internet, su diseño gráfico y los códigos que contiene, son titularidad de la Diputación Provincial 
                    de Badajoz, a no ser que se indique una titularidad diferente. La reproducción, distribución, comercialización o transformación no autorizadas de estas obras 
                    constituye una infracción de los derechos de propiedad intelectual de la Diputación Provincial de Badajoz o de aquel que sea titular. Igualmente, todas las 
                    marcas o signos distintivos de cualquier clase contenidos en el portal están protegidos por Ley.
                </p>
                <p>
                    La utilización no autorizada de la información contenida en este Portal, así como los perjuicios ocasionados en los derechos de propiedad intelectual e 
                    industrial de la Diputación Provincial de Badajoz, pueden dar lugar al ejercicio de las acciones que legalmente correspondan y, si procede, a las responsabilidades 
                    que de dicho ejercicio se deriven.
                </p>
                
                <h1>Política de protección de datos</h1>

                <p>
                    De acuerdo con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo (Reglamento General de Protección de Datos, en adelante RGPD) y la Ley Orgánica 
                    15/1999 de 13 de diciembre de Protección de Datos de Carácter Personal, la Diputación Provincial de Badajoz se compromete al cumplimiento de su obligación de secreto 
                    con respecto a los datos de carácter personal y al deber de tratarlos con confidencialidad. A estos efectos, adoptará las medidas necesarias para evitar su alteración, 
                    pérdida, tratamiento o acceso no autorizado.
                </p>
                <p>
                    La visita al Portal de la Diputación Provincial de Badajoz se efectúa de forma anónima. Los datos de carácter personal que se soliciten, serán aquellos estrictamente 
                    imprescindibles para identificar y atender la solicitud realizada por la persona titular de los mismos o prestar los servicios demandados y no serán tratados ulteriormente 
                    de manera incompatible con dichos fines.
                </p>
                <p>
                    Los datos recogidos de cada titular interesado serán adecuados, pertinentes y no excesivos en relación a las finalidades correspondientes para cada caso, y serán 
                    actualizados siempre que sea necesario.
                </p>
                <p>
                    Estos datos se incorporarán a las correspondientes actividades de tratamiento de la Diputación Provincial de Badajoz y serán tratados de conformidad con la regulación 
                    establecida por el RGPD sobre protección de datos de carácter personal y demás disposiciones de aplicación. Todas las actividades de tratamiento se encuentran recogidas en 
                    el registro de actividades de tratamiento de la Diputación Provincial de Badajoz, según el art.30 RGPD.
                </p>
                <p>
                    La legitimación del tratamiento puede estar basada en el consentimiento de la persona interesada y/o cumplimiento de una obligación legal y/o el cumplimiento de una misión 
                    realizada en interés público y/o la protección de intereses vitales y/o la ejecución de un contrato, según establece el art. 6 RGPD.
                </p>
                <p>
                    El plazo de conservación de los datos dependerá de lo especificado para cada tratamiento de datos personales.
                </p>
                <p>
                    Los datos recabados únicamente serán objeto de cesión, en su caso, previo consentimiento de la persona interesada o en caso de supuesto legal.
                </p>
                <p>
                    Las personas cuyos datos personales sean tratados por la Diputación Provincial de Badajoz podrán ejercitar los derechos de acceso, rectificación, supresión, limitación del 
                    tratamiento, oposición y portabilidad, así como a oponerse a la toma de decisiones individuales automatizadas. Dicha solicitud podrá dirigirse a:
                </p>
                <p>
                    Oficina de Información Administrativa<br/>
                    Teléfono: 924 212 401<br/>
                    e-mail: oia[@]dip-badajoz.es<br/>
                    c/ Felipe Checa, 23 - 06071 Badajoz
                </p>

            </Card>
        )
    }
}

export default withTranslation()<IProps>(LegalNotice)
