import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RouterStore } from "mobx-react-router";
import { inject, observer } from "mobx-react";
import Particles from 'react-particles-js';
import { Col, Row } from 'antd';
import Logo from '../assets/img/logo_respira_intro.png';

type IProps = WithTranslation & {
  routerStore: RouterStore
};


@inject('routerStore')
@observer
class LandingPage extends React.Component<IProps> {

  render() {
    const { t } = this.props;
    const isMobile: boolean = window.innerWidth < 600;

    /*
    // Routing to another page?
    const paramPos = window.location.href.indexOf('?');
    if (paramPos > -1) {
      const params = window.location.href.slice(paramPos + 1).split('=');
      if (params.length === 2)
      {
        if (params[0] === 'target')
          this.props.routerStore.replace('/' + params[1]);
      }
    }
    */

    return (
      <div>
        <div className="hero-wrap">
          <div className="overlay"></div>
          <Particles />
          <div style={{ paddingTop: isMobile ? '10vh' : '10vh', textAlign: 'center' }}>
            <Row gutter={24}>
              <Col
                xs={{ span: 10, offset: 8 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
                xl={{ span: 12, offset: 6 }}
              >
                <img style={{ height: isMobile ? '5em' : '15em' }} src={Logo} alt={t('welcome')} /> 
                <h1 style={{ color: 'white', fontWeight: 100, fontSize: isMobile ? '2em' : '4em' }}>
                  {t('welcome')}
                </h1>
                <p>
                  <Link to="/dashboard">
                    <h3 style={{ color: 'white' }}>{t('enter')}</h3>
                  </Link>
                </p>
                <p>
                  <span style={{ color: 'white' }}>
                    {t('intro_notice_1')}
                    {t('intro_notice_2')}
                    {t('intro_notice_3')}                  
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LandingPage);
