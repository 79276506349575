import Leaflet from 'leaflet';

// marker icons from https://mapicons.mapsmarker.com/
export function getMarkerByDevice(device: any) {
  let iconName = '';
  if (device.user) {
    iconName = 'mines';
  } else {
    let icon = device.type.split('_')[0].toLowerCase();
    iconName = icon === 'respira' || icon === 'aemet' || icon === 'eea' ? icon : 'other';
    if (device.airQualityIndex && device.airQualityIndex > 75) {
      iconName = 'danger';
    }
  }

  const customMarker = new Leaflet.Icon({
    iconUrl: require(`../assets/img/${iconName}.png`),
    iconRetinaUrl: require(`../assets/img/${iconName}.png`),
    iconSize: new Leaflet.Point(40, 55),
  });

  return customMarker;
}
