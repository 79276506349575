import { observable, action } from 'mobx';
import axios from 'axios';
import moment from 'moment';

export default class DataStore {
  @observable isLoading: boolean = false;
  @observable dataChart: Array<any> = [];
  @observable allData: Array<any> = [];

  @action async getDataChart(id: string, pol: string, start: any, end?: any) {
    this.isLoading = true;

    try {
      const data: any = await axios.get(`/data/${id}?pol=${pol}&start=${start}&end=${end}`);
      const dataChart = await this.parsingDate(data);

      //--------------- PRUEBA --------------------
      // console.log('contenido de dataChart antes de SORT');
      // console.log(JSON.stringify(dataChart));
      //-------------------------------------------

      this.dataChart = dataChart.sort((a: any, b: any) => {
        return a.date - b.date;
      });

      //--------------- PRUEBA --------------------
      // console.log('contenido de dataChart después de SORT');
      // console.log(JSON.stringify(this.dataChart));
      //-------------------------------------------

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.dataChart = [];
      return Promise.reject(error);
    }
  }

  @action async getAllDataFromChart(id: string, pol: string) {
    this.isLoading = true;

    try {
      const data: any = await axios.get(`/data/${id}?pol=${pol}&start=year`);
      const dataChart = await this.parsingDate(data);

      //--------------- PRUEBA --------------------
      // console.log('contenido de dataChart antes de SORT');
      // console.log(JSON.stringify(dataChart));
      //-------------------------------------------

      this.dataChart = dataChart.sort((a: any, b: any) => {
        return a.date - b.date;
      });

      //--------------- PRUEBA --------------------
      // console.log('contenido de dataChart después de SORT');
      // console.log(JSON.stringify(this.dataChart));
      //-------------------------------------------

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.allData = [];
      return Promise.reject(error);
    }
  }

  private parsingDate = async (data: any) => {
    const newData = data.map((d: any) => {
      return {
        date: moment.utc(d.date, 'DD/MM/YYYY HH:mm').unix(),
        // date: moment
        //   .utc(d.date, 'DD/MM/YYYY HH:mm')
        //   .local()
        //   .format('DD/MM/YYYY HH:mm'),
        value: d.value,
        min: d.min,
        max: d.max,
        pollutant: d.pollulant,
      };
    });
    return newData;
  };
}
