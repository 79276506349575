import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Form as AntForm, Input, Button, Row, Col, Card, PageHeader, Icon } from 'antd';
import { Formik, Form, Field, FormikActions, FormikValues, FieldProps } from 'formik';
import { RouterStore } from "mobx-react-router";
import { inject, observer } from "mobx-react";
import * as yup from 'yup';
import AuthStore, { IEmailForm } from "../stores/AuthStore";
import { InputError } from '../components';


type IProps = WithTranslation & {
  routerStore: RouterStore,
  AuthStore?: AuthStore
}

interface IState {
  initialValues: IEmailForm
}

@inject('routerStore', 'AuthStore')
@observer
class ResetPassword extends React.Component<IProps, IState> {

  /**
   * Field validation schema
   */
  validationSchema: any;

  /**
   * Class constructor
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      initialValues: { email: '' }
    }

    // Define field validation schema        
    this.validationSchema = {
      email: yup.string()
      .email(props.t('validation_scheme.invalid_email'))
      .required(props.t('validation_scheme.required_email'))
    }
  }

  /**
   * Submit e-mail address to server for password recovery
   */
  submitEmailAddr = async (values: FormikValues, actions: FormikActions<FormikValues>) => {
    actions.setSubmitting(true);
    try {
      await this.props.AuthStore!.requestPasswordReset(values.email);
      this.props.routerStore.replace('/info?title=reset_password&body=success.reset_password');
    } catch (error) {
      this.props.routerStore.replace('/info?title=reset_password&body=errors.reset_password');
    }
    actions.setSubmitting(false);
  }

  /**
   * Render page
   */
  render() {
    const { initialValues } = this.state;
    const { t } = this.props;

    return (
      <Card className='body-content' title={
        <PageHeader title={t('request_password_reset')} style={{ padding: 0 }} />
      }>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={this.submitEmailAddr}
          validationSchema={yup.object().shape(this.validationSchema)}
          render={({ isSubmitting }) => (
            <Row type="flex" justify="center">
              <Col span={8}>
                <Form>
                  <AntForm.Item label={t('email')} required>
                    <Field name="email" render={({ field }: FieldProps) => (
                        <Input {...field}
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('placeholder_email_login')} />
                    )} />
                    <InputError title='email' />
                  </AntForm.Item>
                  <AntForm.Item>
                    <Button block type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}
                        style={{ borderRadius: '1em' }}>
                        {t('send_request')}
                    </Button>
                  </AntForm.Item>
                </ Form >
                </Col>
            </Row>
          )}
        />
      </Card>
    )
  }
}

export default withTranslation()<IProps>(ResetPassword)
