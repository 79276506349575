import React from 'react';
import { Menu, Avatar, Icon, Modal, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import AuthStore from '../stores/AuthStore';
import { withTranslation, WithTranslation } from 'react-i18next';
import SpanishFlag from '../assets/flags/spanish.svg';
import EnglishFlag from '../assets/flags/english.svg';
import { RouterStore } from 'mobx-react-router';

const { SubMenu } = Menu;

type IProps = WithTranslation & {
  AuthStore?: AuthStore;
  routerStore?: RouterStore;
};

@inject('AuthStore', 'routerStore')
@observer
class MenuHeader extends React.Component<IProps> {
  askToLogout() {
    const { t } = this.props;

    Modal.confirm({
      title: t('log_out'),
      content: t('log_out_description'),
      okType: 'danger',
      okText: t('log_out'),
      cancelText: t('cancel'),
      maskClosable: true,
      onOk: () => {
        this.props.AuthStore!.logout();
        this.props.routerStore!.replace('/dashboard');
      },
    });
  }

  isModalVisible(status: boolean) {
    this.props.AuthStore!.changeModalLoginVisible(status);
  }

  setLanguage = (language: string) => {
    this.props.i18n.changeLanguage(language);
  };

  getActiveMenu = (lng: string) => {
    if (lng === this.props.i18n.language) return { backgroundColor: '#65b15d', color: 'white' };
    return {};
  };

  render() {
    const { user } = this.props.AuthStore!;
    const { t } = this.props;

    return (
      <span style={{ flex: '1 0 auto' }}>
        {user ? (
          <Menu
            mode='horizontal'
            className='header-menu'
            selectable={false}
            overflowedIndicator={<Icon type='menu' style={{ color: 'white' }} />}
          >
            <SubMenu
              title={
                <span className='menu-item'>
                  <Icon type='question-circle' style={{ marginRight: '.5em' }} />
                  {t('support')}
                </span>
              }
            >
              <Menu.Item key='documentation'>
                <a href={t('documentation_link')} rel='noopener noreferrer' target='_blank'>
                  <span>
                    <Icon type='book' style={{ marginRight: '.5em' }} />
                    {t('documentation')}
                  </span>
                </a>
              </Menu.Item>
              <Menu.Item key='forum'>
                <a href={t('forum_link')} rel='noopener noreferrer' target='_blank'>
                  <span>
                    <Icon type='coffee' style={{ marginRight: '.5em' }} />
                    {t('user_forum')}
                  </span>
                </a>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              title={
                <span className='menu-item'>
                  <Icon type='share-alt' style={{ marginRight: '.5em' }} />
                  {t('devices')}
                </span>
              }
            >
              <Menu.Item key='devices'>
                <Link to='/devices'>
                  <span>
                    <Icon type='monitor' style={{ marginRight: '.5em' }} />
                    {t('my_devices')}
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key='device-type'>
                <Link to='/devices/type'>
                  <span>
                    <Icon type='tags' style={{ marginRight: '.5em' }} />
                    {t('device_profiles')}
                  </span>
                </Link>
              </Menu.Item>
              {user.role === 'Admin' && (
                <Menu.Item key='aemet-eea'>
                  <Link to='/aemet-eea'>
                    <span>
                      <Icon type='cloud-o' style={{ marginRight: '.5em' }} />
                      {t('aemet_eea_stations')}
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {user.role === 'Admin' && (
                <Menu.Item key='endpoints'>
                  <Link to='/manage/endpoints'>
                    <span>
                      <Icon type='tool' style={{ marginRight: '.5em' }} />
                      {t('supported_readings')}
                    </span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>

            <SubMenu
              title={
                <span className='menu-item'>
                  <Icon type='bell' style={{ marginRight: '.5em' }} />
                  {t('alerts')}
                </span>
              }
            >
              <Menu.Item key='manage_alerts'>
                <Link to='/alerts/manage'>
                  <span>
                    <Icon type='thunderbolt' style={{ marginRight: '.5em' }} />
                    {t('manage_alerts')}
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key='alert_console'>
                <Link to='/alerts/console'>
                  <span>
                    <Icon type='alert' style={{ marginRight: '.5em' }} />
                    {t('alert_console')}
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>

            {user.role === 'Admin' && (
              <Menu.Item key='users'>
                <Link to='/users'>
                  <span className='menu-item'>
                    <Icon type='team' style={{ marginRight: '.5em' }} />
                    {t('users')}
                  </span>
                </Link>
              </Menu.Item>
            )}
            <SubMenu
              title={
                <span className='menu-item'>
                  <Icon type='solution' style={{ marginRight: '.5em' }} />
                  {t('profile')}
                </span>
              }
            >
              <Menu.Item key='settings'>
                <Link to='/me/profile'>
                  <span>
                    <Icon type='user' style={{ marginRight: '.5em' }} />
                    {t('my_profile')}
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item onClick={() => this.askToLogout()} key={'log_out'}>
                <span>
                  <Icon type='poweroff' style={{ marginRight: '.5em' }} />
                  {t('log_out')}
                </span>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              title={
                <span className='menu-item'>
                  <Icon type='global' style={{ marginRight: '.5em' }} />
                  {t('language')}
                </span>
              }
            >
              <Menu.Item key='en' onClick={() => this.setLanguage('en')} style={this.getActiveMenu('en')}>
                <span>
                  <Avatar src={EnglishFlag} style={{ marginRight: '.5em' }} />
                  English
                </span>
              </Menu.Item>
              <Menu.Item key='es' onClick={() => this.setLanguage('es')} style={this.getActiveMenu('es')}>
                <span>
                  <Avatar src={SpanishFlag} style={{ marginRight: '.5em' }} />
                  Castellano
                </span>
              </Menu.Item>
            </SubMenu>
          </Menu>
        ) : (
          <Menu
            mode='horizontal'
            className='header-menu'
            selectable={false}
            overflowedIndicator={<Icon type='menu' style={{ color: 'white' }} />}
          >
            <Menu.Item key={'login'} onClick={() => this.isModalVisible(true)}>
              <Tooltip title={t('login')} placement='left'>
                <span className='menu-item'>
                  <Icon type='login' style={{ marginRight: '.5em' }} />
                  {t('login')}
                </span>
              </Tooltip>
            </Menu.Item>

            <SubMenu
              title={
                <span className='menu-item'>
                  <Icon type='question-circle' style={{ marginRight: '.5em' }} />
                  {t('support')}
                </span>
              }
            >
              <Menu.Item key='documentation'>
                <a href={t('documentation_link')} rel='noopener noreferrer' target='_blank'>
                  <span>
                    <Icon type='book' style={{ marginRight: '.5em' }} />
                    {t('documentation')}
                  </span>
                </a>
                } >
              </Menu.Item>
              <Menu.Item key='forum'>
                <a href={t('forum_link')} rel='noopener noreferrer' target='_blank'>
                  <span>
                    <Icon type='coffee' style={{ marginRight: '.5em' }} />
                    {t('user_forum')}
                  </span>
                </a>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              title={
                <span className='menu-item'>
                  <Icon type='global' style={{ marginRight: '.5em' }} />
                  {t('language')}
                </span>
              }
            >
              <Menu.Item key='en' onClick={() => this.setLanguage('en')} style={this.getActiveMenu('en')}>
                <span>
                  <Avatar src={EnglishFlag} style={{ marginRight: '.5em' }} />
                  English
                </span>
              </Menu.Item>
              <Menu.Item key='es' onClick={() => this.setLanguage('es')} style={this.getActiveMenu('es')}>
                <span>
                  <Avatar src={SpanishFlag} style={{ marginRight: '.5em' }} />
                  Castellano
                </span>
              </Menu.Item>
            </SubMenu>
          </Menu>
        )}
      </span>
    );
  }
}

export default withTranslation()(MenuHeader);
