
import AuthStore from './AuthStore';
import UsersStore from "./UsersStore";
import DashboardStore from "./DashboardStore";
import DevicesStore from './DevicesStore'
import EntitiesStore from './EntitiesStore'
import DataStore from './DataStore'
import AlertsStore from './AlertsStore'

export class RootStore {
    public AuthStore: AuthStore;
    public UsersStore: UsersStore;
    public DashboardStore: DashboardStore;
    public DevicesStore: DevicesStore;
    public EntitiesStore: EntitiesStore;
    public DataStore: DataStore;
    public AlertsStore: AlertsStore;

    constructor() {
        this.AuthStore = new AuthStore();
        this.UsersStore = new UsersStore();
        this.DashboardStore = new DashboardStore();
        this.DevicesStore = new DevicesStore()
        this.EntitiesStore = new EntitiesStore();
        this.DataStore = new DataStore();
        this.AlertsStore = new AlertsStore();
    }
}

const rootStore = new RootStore();

export default rootStore;