import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Form as AntForm, Button, Row, Col, Card, PageHeader, Switch } from 'antd';
import { Formik, Form, Field, FormikActions, FormikValues } from 'formik';
import { RouterStore } from "mobx-react-router";
import { inject, observer } from "mobx-react";
import AuthStore from "../stores/AuthStore";
import EntitiesStore from '../stores/EntitiesStore';


type IProps = WithTranslation & {
  routerStore: RouterStore,
  EntitiesStore: EntitiesStore,
  AuthStore: AuthStore
}

type Istate = {
  aemetFederState: boolean,
  eeaFederState: boolean
};

@inject('routerStore', 'EntitiesStore', 'AuthStore')
@observer
class AemetEeaStations extends React.Component<IProps, Istate> {

  state: Istate = {
    aemetFederState: false,
    eeaFederState: false,
  };

  private initialValues: any = {
    aemetFederState: this.state.aemetFederState,
    eeaFederState: this.state.eeaFederState
  };

  /**
   * Run once all components have been mounted
   */
  async componentDidMount() {
    this.loadFederationData();
  }

  /**
   * Load federation information
   */
  private async loadFederationData() {
    const { EntitiesStore, AuthStore } = this.props;

    try {
      const userId: string | undefined = AuthStore.user!.fw_name;

      if (userId) {
        // Get AEMET federation ID and state
        const aemetFederId: string = await EntitiesStore.getFederationId('AEMET', 'AEMET_OBSERVATION');
        if (aemetFederId) {
          if (aemetFederId !== '')
            this.setState({ aemetFederState: true });
        }

        // Get EEA federation ID and state
        const eeaFederId: string = await EntitiesStore.getFederationId('EEA', 'EEA_POLLUTION');
        if (eeaFederId) {
          if (eeaFederId !== '')
          this.setState({ eeaFederState: true });
        }
      }
    } catch (error) {
      this.props.routerStore.replace('/info?title=federation_aemet_eea&body=errors.federation');
    }
  }

  /**
   * Handler for the AEMET federation switch
   */
  private handleAemetFederationChange = async (checked: boolean) => {
    this.setState({ aemetFederState: checked });
  };

  /**
   * Handler for the EEA federation switch
   */
  private handleEeaFederationChange = async (checked: boolean) => {
    this.setState({ eeaFederState: checked });
  };

  /**
   * Submit AEMET and EEA federations
   */
  submitFederations = async (values: FormikValues, actions: FormikActions<FormikValues>) => {
    const { EntitiesStore, AuthStore } = this.props;
    actions.setSubmitting(true);
    
    try {
      const userId: string | undefined = AuthStore.user!.fw_name;

      if (userId) {
        // Send requests to server
        await EntitiesStore.updateFederation('AEMET', 'AEMET_OBSERVATION', this.state.aemetFederState);
        await EntitiesStore.updateFederation('EEA', 'EEA_POLLUTION', this.state.eeaFederState);

        this.props.routerStore.replace('/info?title=federation_aemet_eea&body=success.federation');
      }
    } catch (error) {
      this.props.routerStore.replace('/info?title=federation_aemet_eea&body=errors.federation');
    }
    actions.setSubmitting(false);
  }

  /**
   * Render page
   */
  render() {
    const { t } = this.props;

    return (
      <Card className='body-content' title={
        <PageHeader title={t('federation_aemet_eea')} style={{ padding: 0 }} />
      }>
        <Formik
          enableReinitialize
          initialValues={this.initialValues}
          onSubmit={this.submitFederations}
          render={({ isSubmitting }) => (
            <Row type="flex" justify="center">
              <Col span={8}>
                <Form>
                  <AntForm.Item label={t('federation_aemet')}>
                    <Field
                      name='aemetFederState'
                      render={() => 
                        <Switch
                          checkedChildren='on'
                          unCheckedChildren='off'
                          checked={this.state.aemetFederState}
                          onChange={checked => this.handleAemetFederationChange(checked)}
                        />
                      }
                    />
                  </AntForm.Item>
                  <AntForm.Item label={t('federation_eea')}>
                    <Field
                      name='eeaFederState'
                      render={() => 
                        <Switch
                          checkedChildren='on'
                          unCheckedChildren='off'
                          checked={this.state.eeaFederState}
                          onChange={checked => this.handleEeaFederationChange(checked)}
                        />
                      }
                    />
                  </AntForm.Item>
                  <AntForm.Item>
                    <Button block type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}
                        style={{ borderRadius: '1em' }}>
                        {t('save')}
                    </Button>
                  </AntForm.Item>
                </ Form >
              </Col>
            </Row>
          )}
        />
      </Card>
    )
  }
}

export default withTranslation()<IProps>(AemetEeaStations)
