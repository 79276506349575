import { observable, action } from 'mobx';
import axios from "axios";

import { IPaginationProps, IPaginationParams } from '../utils';
import { User } from '../models';

export default class UsersStore {
    @observable isLoading: boolean = false;
    @observable users: IPaginationProps<User> = { docs: [] };

    @action async getUsers(params?: IPaginationParams) {
        this.isLoading = true;

        try {
            this.users = await axios.get('/users', { params });
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            return Promise.reject(error);
        }
    }

    createUser(user: User): Promise<User> {
        return axios.post('/users', user);
    }

    @action updateUser(user: User, reload: boolean = false) {
        this.isLoading = true
        return axios.put(`/users/${user._id}`, user)
            .then(() => reload ? this.getUsers() : undefined)
            .finally(() => this.isLoading = false);
    }

    @action deleteUser(userId: string, reload: boolean = false) {
        this.isLoading = true
        return axios.delete(`/users/${userId}`)
            .then(() => reload ? this.getUsers() : undefined)
            .finally(() => this.isLoading = false);
    }
}