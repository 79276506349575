import { observable, action } from 'mobx';
import axios from 'axios';

import { Device, IDeviceCreation, Attribute } from '../models';
import { IDeviceType } from '../utils';

export default class DevicesStore {
  @observable isLoading: boolean = false;
  @observable devices: Array<Device> = [];
  @observable myDevices: any = { count: 0, devices: [] };
  @observable selectedDevice: Device = {};
  @observable deviceTypes: Array<IDeviceType> = [];

  @action async getDevice(deviceId: string) {
    this.isLoading = true;

    try {
      this.selectedDevice = await axios.get(`/devices/${deviceId}`);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  @action async getDevicesByUser() {
    this.isLoading = true;
    try {
      this.myDevices = await axios.get('/devices/user');
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.myDevices = [];
      return Promise.reject(error);
    }
  }

  @action async createDevice(device: IDeviceCreation) {
    this.isLoading = true;
    try {
      const newDevice: IDeviceCreation = await axios.post(`/devices`, device);
      this.isLoading = false;
      return newDevice;
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  updateEntityAttributes(deviceId: string, location: any, params?: { options: 'keyValues' }) {
    return axios.put(`/devices/${deviceId}/attrs`, location, { params });
  }

  @action async getDeviceTypesByUser(): Promise<Array<IDeviceType> | undefined> {
    try {
      const res: any = await axios.get(`/devices/devicetype`);
      if (res.count > 0) {
        this.isLoading = false;
        this.deviceTypes = res.services;
        return res.services;
      } else {
        this.isLoading = false;
        this.deviceTypes = [];
      }
    } catch (error) {
      this.isLoading = false;
      return Promise.reject(error);
    }
  }

  createDeviceType(deviceType: IDeviceType): Promise<IDeviceType> {
    return axios.post(`/devices/devicetype`, deviceType);
  }

  editDeviceType(deviceType: IDeviceType): Promise<IDeviceType> {
    return axios.put(`/devices/devicetype`, deviceType);
  }

  /**
   * Delete device type or service group
   * 
   * @param deviceType : device type structure
   * 
   * @return response from fiware IoT agent
   */
  deleteDeviceType(deviceType: IDeviceType) {
    this.isLoading = true;
    return axios.delete(`/devices/devicetype/${deviceType.apikey}`)
        .then(() => this.getDeviceTypesByUser())
        .finally(() => this.isLoading = false);
  }

  getEndpoints(params?: { search?: string }): Promise<Array<Attribute>> {
    return axios.get('/endpoints', { params });
  }

  addEndpoint(attribute: Attribute) {
    return axios.post('/endpoints', attribute);
  }

  deleteEndpoint(attributeId: string) {
    return axios.delete(`/endpoints/${attributeId}`);
  }
}
