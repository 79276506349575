import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import { Button, message, Form as AntForm, Input, Select } from 'antd';
import { Formik, Form, Field, FormikActions, FieldProps } from 'formik';
import * as yup from 'yup';

import DevicesStore from '../stores/DevicesStore';
import AuthStore from '../stores/AuthStore';
import { IEndpoint } from '../models';
import { InputError } from '.';
import { IDeviceType } from '../utils';
import { isNullOrUndefined } from 'util';
import { ENDPOINTS_DESCRIPTION, IEndpointsDescription } from '../utils/constants';

type IProps = WithTranslation & {
  DevicesStore: DevicesStore;
  AuthStore: AuthStore;
  routerStore: RouterStore;
  closeModal: (getTypes?: boolean) => void;
  deviceType?: IDeviceType;
  isEdit?: boolean;
};

type InitialValues = {
  type: string;
  attributes: Array<string>;
  //nameDeviceType: string;
};

type IState = {
  initialValues: InitialValues;
  endpoints: Array<IEndpoint>;
};
@inject('DevicesStore', 'AuthStore')
@observer
class AddDeviceType extends React.Component<IProps, IState> {
  state: IState = {
    initialValues: {
      type: '',
      attributes: [],
      //nameDeviceType: '',
    },
    endpoints: [],
  };

  componentDidMount() {
    this.getEndpoints();
  }

  getEndpoints = async () => {
    const { getEndpoints } = this.props.DevicesStore;

    const endpoints = await getEndpoints();
    if (this.props.isEdit && this.props.deviceType) {
      const { attributes, entity_type } = this.props.deviceType;
      const initialValues: any = { type: entity_type, attributes: attributes };
      //Parse Attributes from service group to attributes from endpoints._id
      initialValues.attributes = await Promise.all(
        this.props.deviceType.attributes
          .map((attribute: any) => {
            const endpoint = endpoints
              .filter(d => !['name', 'description', 'lat', 'lon'].includes(d.object_id!))
              .find(d => d.name === attribute.name);
            if (endpoint) {
              return endpoint._id;
            }
            return undefined;
          })
          .filter((attribute: any) => !isNullOrUndefined(attribute)),
      );

      this.setState({ initialValues });
    }
    this.setState({ endpoints });
  };

  onSubmit = async (values: InitialValues, formikActions: FormikActions<InitialValues>) => {
    const { createDeviceType, editDeviceType } = this.props.DevicesStore;

    const defaultAttributes = this.state.endpoints
      .filter(d => ['lat', 'lon', 'name', 'description'].includes(d.object_id!))
      .map(d => d._id!);

    values.type = await this.checkNameType(values.type);

    formikActions.setSubmitting(true);
    try {
      if (this.props.isEdit) {
        await editDeviceType({ ...this.props.deviceType, attributes: values.attributes.concat(defaultAttributes) });
      } else {
        await createDeviceType(values);
      }
      this.props.closeModal(true);
    } catch (error) {
      message.error(this.props.t(`errors.${this.props.isEdit ? 'edit_device_profile' : 'add_device_profile'}`));
    }
    formikActions.setSubmitting(false);
  };

  async checkNameType(type: string) {
    let array = type.split(' ');
    let newString = '';
    if (array.length > 1) {
      for (var i = 0; i < array.length; i++) {
        if (i === array.length - 1) newString = `${newString}${array[i]}`;
        else newString = `${newString}${array[i]}_`;
      }
      return newString;
    } else return array[0];
  }

  handleAddEndpoint = () => {
    this.props.routerStore.push('/manage/endpoints');
    this.props.closeModal();
  };

  handleChange = (event: any) => {
    const newName = event.target.value.replace(/[^a-zA-Z0-9 _]/gim, '');

    this.setState(prevState => ({
      initialValues: {
        ...prevState.initialValues,
        type: newName,
      },
    }));
  };

  renderSelectOption = (endpoint: IEndpoint) => {
    const { t, i18n } = this.props;
    let name: string | undefined = endpoint.name;
    let unit: string = endpoint.unit ? `${t('in')} ${endpoint.unit}` : '';

    const endpointDescription: IEndpointsDescription | undefined = ENDPOINTS_DESCRIPTION.find(
      e => e.fiware === endpoint.name,
    );
    if (endpointDescription) {
      name = endpointDescription.description[i18n.language];
      if (endpointDescription.unit && endpointDescription.unitDescription)
        unit = `${t('in')} ${endpointDescription.unitDescription[i18n.language]}`;
    }

    return <Select.Option key={endpoint._id}>{`${name} ${unit}`}</Select.Option>;
  };

  render() {
    const { initialValues, endpoints } = this.state;
    const { user } = this.props.AuthStore;
    const { t, isEdit } = this.props;

    return (
      <div>
        <small>{t('info_device_profile')}</small>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validationSchema={yup.object().shape({
            type: yup.string().required(t('validation_scheme.required_name')),
          })}
          render={({ isSubmitting, setFieldValue }) => (
            <Form>
              <AntForm.Item label={t('name')} required>
                <small>{t('only_alphanumeric_allowed')}</small>
                <Field
                  name='type'
                  render={({ field }: FieldProps) => (
                    <Input
                      {...field}
                      disabled={isEdit}
                      placeholder={t('temperature_sensor')}
                      value={this.state.initialValues.type}
                      onChange={event => this.handleChange(event)}
                    />
                  )}
                />
                <InputError title='type' />
              </AntForm.Item>
              <AntForm.Item label={t('reading')} required>
                <small>{t('multiple_readings')}</small>
                <Field
                  name='attributes'
                  render={({ field }: FieldProps) => (
                    <Select
                      {...field}
                      dropdownStyle={{ zIndex: 1700 }}
                      mode={'multiple'}
                      onChange={(value: any) => setFieldValue('attributes', value)}
                      filterOption={(inputValue, option) => {
                        return (option.props.children as string).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                      }}
                      placeholder={t('reading')}
                      notFoundContent={
                        <div>
                          {t('no_data')}
                          <br />
                          {user && user.role === 'Admin' ? (
                            <Button type='link' onClick={this.handleAddEndpoint}>
                              {t('manage_supported_reading')}
                            </Button>
                          ) : (
                            <b>{t('contact_admin')}</b>
                          )}
                        </div>
                      }
                    >
                      {endpoints
                        .filter(d => !['name', 'description', 'lat', 'lon'].includes(d.object_id!))
                        .map(this.renderSelectOption)}
                    </Select>
                  )}
                />
                <InputError title='attributes' />
              </AntForm.Item>
              <AntForm.Item>
                <Button onClick={() => this.props.closeModal()}>{t('cancel')}</Button>
                <Button
                  type='primary'
                  style={{ margin: '0 1em' }}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  htmlType='submit'
                >
                  {t('save')}
                </Button>
              </AntForm.Item>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default withTranslation()<IProps>(AddDeviceType);
