import React from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';
import { GoogleLayer } from 'react-leaflet-google';
import { useTranslation } from 'react-i18next';

const { BaseLayer } = LayersControl;

const key = 'AIzaSyDEG4lyorD61vnJoAHG0FkQERZ-McElZyg';
const road = 'ROADMAP';
// const satellite = 'SATELLITE';

export const LayersMap: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LayersControl position="topleft">
      <BaseLayer checked name={t('maps.openStreetMap.standard')}>
        <TileLayer
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          minZoom={0}
          maxZoom={18}
          maxNativeZoom={19}
          minNativeZoom={0}
        />
      </BaseLayer>
      <BaseLayer name="ESRI Gray">
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
          maxZoom={16}
          attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
        ></TileLayer>
      </BaseLayer>
      {/* <BaseLayer name={t('maps.google.map')}>
        <GoogleLayer googlekey={key} maptype={road} version={3.36} />
      </BaseLayer> */}
    </LayersControl>
  );
};
