import React from 'react';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Form as AntForm, Input, Button, message, Icon } from 'antd';
import { Formik, Field, FormikActions, FieldProps } from 'formik';
import { Link } from 'react-router-dom';

import * as yup from 'yup';

import { InputError } from '.';

import UsersStore from '../stores/UsersStore';

import { User } from '../models';


interface IProps extends WithTranslation {
  UsersStore?: UsersStore,
  onFinish: (key: string) => void,
  closeModal: () => void
}

interface IState {
  initialValues: User;
}

@inject('UsersStore')
@observer
class SignUpForm extends React.Component<IProps, IState> {
  validationSchema: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      initialValues: { email: '', password: '', name: '', surname: '', role: 'Developer' },
    };
    this.validationSchema = {
      email: yup
        .string()
        .email(props.t('validation_scheme.invalid_email'))
        .required(props.t('validation_scheme.required_email')),
      password: yup.string().required(props.t('validation_scheme.required_password')),
      name: yup.string().required(props.t('validation_scheme.required_name')),
      surname: yup.string().required(props.t('validation_scheme.required_surname')),
    };
  }

 /**
  * Close component
  */
  closeAll = () => {        
    this.props.closeModal();
  }

  submitLogin = async (values: User, actions: FormikActions<User>) => {
    actions.setSubmitting(true);
    try {
      await this.props.UsersStore!.createUser(values);
      message.success(this.props.t('success.sign_up'));
      actions.resetForm();
      this.props.onFinish('login');
    } catch (error) {
      message.error(this.props.t('errors.sign_up'));
    }
    actions.setSubmitting(false);
  };

  render() {
    const { initialValues } = this.state;
    const { t } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={yup.object().shape(this.validationSchema)}
        onSubmit={this.submitLogin}
        render={({ isSubmitting, handleSubmit }) => (
          <AntForm onSubmit={handleSubmit}>
            <AntForm.Item required className='white-text'>
              <Field
                name='email'
                render={({ field }: FieldProps) => (
                  <Input
                    {...field}
                    prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={t('placeholder_email_login')}
                  />
                )}
              />
              <InputError title='email' />
            </AntForm.Item>
            <AntForm.Item required className='white-text'>
              <Field
                name='password'
                render={({ field }: FieldProps) => (
                  <Input
                    {...field}
                    prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={t('placeholder_password_login')}
                    type={'password'}
                    autoComplete='password'
                  />
                )}
              />
              <InputError title='password' />
            </AntForm.Item>
            <AntForm.Item required className='white-text'>
              <Field
                name='name'
                render={({ field }: FieldProps) => <Input {...field} placeholder={t('placeholder_name')} />}
              />
              <InputError title='name' />
            </AntForm.Item>
            <AntForm.Item required className='white-text'>
              <Field
                name='surname'
                render={({ field }: FieldProps) => <Input {...field} placeholder={t('placeholder_surname')} />}
              />
              <InputError title='surname' />
            </AntForm.Item>
            <AntForm.Item>
                <span style={{ color: 'white' }}>
                  <label>{t('legal_notice_info')}</label>
                </span>
                <br/>
              <Link onClick={this.closeAll} to="legal-notice" style={{ color: '#03ecfc' }}>
                {t('legal_notice')}
              </Link>
            </AntForm.Item>        
            <AntForm.Item>
              <Button
                block
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}
                loading={isSubmitting}
                style={{ borderRadius: '1em' }}
              >
                {t('save')}
              </Button>
            </AntForm.Item>
          </AntForm>
        )}
      />
    );
  }
}

export default withTranslation()(SignUpForm);
