import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouterStore } from 'mobx-react-router';
import { inject, observer } from 'mobx-react';
import { Card, PageHeader, Badge, Button, Tooltip, Modal, Switch } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';

import { ConfiguredAlert } from '../models';
import AlertsStore from '../stores/AlertsStore';
import AuthStore from '../stores/AuthStore';
import DevicesStore from '../stores/DevicesStore';
import EntitiesStore from '../stores/EntitiesStore';

import AddAlert from '../components/AddAlert';

type IProps = WithTranslation & {
  routerStore: RouterStore;
  AlertsStore: AlertsStore;
  AuthStore: AuthStore;
  DevicesStore: DevicesStore;
  EntitiesStore: EntitiesStore;
};

@inject('routerStore', 'AlertsStore', 'AuthStore', 'DevicesStore', 'EntitiesStore')
@observer
class AlertsManage extends React.Component<IProps> {
  componentDidMount() {
    this.getAlerts();
  }

  getAlerts() {
    const { AuthStore } = this.props;
    const user: any = AuthStore!.user;
    return this.props.AlertsStore.getConfiguredAlerts(user.fw_name);
  }

  switchAlert(id: any) {
    return this.props.AlertsStore.switchAlert(id);
  }

  deleteAlert(id: any) {
    return this.props.AlertsStore.deleteAlert(id);
  }

  tableOnChange = async () => {
    await this.getAlerts();
  };

  handleSubmit = () => {
    console.log(this.props.DevicesStore.myDevices);
    return '';
  };

  handleCloseModal() {
    Modal.destroyAll();
  }

  handleAddDevice = async () => {
    const { t, AuthStore, DevicesStore, routerStore, AlertsStore, EntitiesStore } = this.props;
    Modal.confirm({
      width: '75%',
      title: t('add_alert'),
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <AddAlert
          AlertsStore={AlertsStore}
          DevicesStore={DevicesStore}
          AuthStore={AuthStore}
          EntitiesStore={EntitiesStore}
          routerStore={routerStore}
          isEdit={false}
          closeModal={() => this.handleCloseModal()}
        ></AddAlert>
      ),
    });
  };

  render() {
    const { routerStore, t } = this.props;
    const { isLoading, configuredAlerts } = this.props.AlertsStore;
    const columns: Array<ColumnProps<ConfiguredAlert>> = [
      { title: t('id'), dataIndex: 'id', key: 'id' },
      { title: t('target'), dataIndex: 'target', key: 'target' },
      { title: t('operator'), dataIndex: 'operator', key: 'operator', render: (text, record) => t(text) },
      { title: t('value'), dataIndex: 'value', key: 'value' },
      {
        title: t('status'),
        dataIndex: 'active',
        key: 'active',
        render: (text, record) => (
          <span>
            <Badge status={text ? 'success' : 'error'} text={t(`${text ? 'enabled' : 'disabled'}`)} />
          </span>
        ),
      },
      {
        title: t('actions'),
        key: 'action',
        render: (text, record) => (
          <span>
            <Switch
              defaultChecked={record.active}
              checkedChildren='Enabled'
              unCheckedChildren='Disabled'
              onChange={() => this.switchAlert(record._id)}
            />
            <Tooltip title={t('delete')}>
              <Button type='link' icon='delete' onClick={() => this.deleteAlert(record._id)} />
            </Tooltip>
          </span>
        ),
      },
    ];
    const CardTitle = () => (
      <PageHeader
        style={{}}
        onBack={() => routerStore.goBack()}
        title={t('alerts')}
        extra={[
          <Button key='add_alert' icon='plus' onClick={() => this.handleAddDevice()}>
            {t('add_alert')}
          </Button>,
        ]}
      />
    );

    return (
      <Card headStyle={{ padding: 0 }} className='body-content card-title-no-padding' title={<CardTitle />}>
        <Table
          loading={isLoading}
          rowKey={(row, index) => row._id || index.toString()}
          dataSource={configuredAlerts}
          columns={columns}
          pagination={{
            size: 'small',
            hideOnSinglePage: true,
          }}
          onChange={this.tableOnChange}
        />
      </Card>
    );
  }
}

export default withTranslation()(AlertsManage);
