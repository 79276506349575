import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import EntitiesStore from '../stores/EntitiesStore';
import DashboardStore from '../stores/DashboardStore';
import Leaflet from 'leaflet';
import VirtualizedSelect from 'react-virtualized-select';

type IProps = WithTranslation & {
  EntitiesStore?: EntitiesStore;
  DashboardStore?: DashboardStore;
};

type Istate = {
  selectedEntity: string;
};
@inject('EntitiesStore', 'DashboardStore')
@observer
class FloatingSearch extends React.Component<IProps, Istate> {
  state: Istate = {
    selectedEntity: '',
  };

  onSelectDevice = async (value: any) => {
    this.setState({ selectedEntity: value });
    this.props.DashboardStore!.setshowSidebar(true);
    await this.props.EntitiesStore!.getEntity(value);
    let device = await this.props.EntitiesStore!.selectedEntity;
    let latlng = Leaflet.latLng(device.latitude || 0, device.longitude || 0);
    this.props.DashboardStore!.map!.leafletElement.flyTo(latlng, 14);
  };

  /**
   * Load list of entities and replace empty name fields by the entity id
   */
  loadEntities = () => {
    const entities = this.props.EntitiesStore!.entities;
    //const entityList = entities.filter(x => x.name);

    const entityList = entities.filter(x => {
      return x.name && x.name !== '' && x.name !== ' ';
    });

    // const entityList = entities.map((entity: any) => {
    //   entity.name = entity.name ? entity.name : entity.id;
    //   return entity;
    // });

    return entityList;
  };

  render() {
    const entities = this.loadEntities();
    const { t } = this.props;
    const { selectedEntity } = this.state;
    return (
      <div className="floating-search">
        <div style={{ width: '50vw', margin: '0 auto' }}>
          <VirtualizedSelect
            clearable={false}
            disabled={false}
            labelKey="name"
            multi={false}
            options={entities}
            onChange={value => this.onSelectDevice(value)}
            searchable={true}
            simpleValue
            value={selectedEntity}
            valueKey="id"
            placeholder={t('search_devices')}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(FloatingSearch);
