import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Form as AntForm, Input, Button, Row, Col, message, Modal, Card, PageHeader, Select, Divider } from "antd";
import { Formik, Form, Field, FormikActions, FieldProps } from 'formik';
import * as yup from 'yup';
import { RouterStore } from "mobx-react-router";
import { inject, observer } from "mobx-react";
import { LatLngLiteral } from "leaflet";

import LocationMap from "../components/LocationMap";
import { InputError, AddDeviceType } from "../components";

import DevicesStore from "../stores/DevicesStore";
import AuthStore from "../stores/AuthStore";
import { IDeviceCreation, Attribute } from "../models";

import { IDeviceType } from "../utils";
import { ENDPOINTS_DESCRIPTION, IEndpointsDescription } from "../utils/constants";

type IProps = WithTranslation & {
    DevicesStore: DevicesStore
    routerStore: RouterStore
    AuthStore: AuthStore
}

type Istate = {
    location?: LatLngLiteral;
    deviceTypes: Array<IDeviceType>;
    device: IDeviceCreation;
    attributes?: Array<string | Attribute>;
}

@inject('DevicesStore', 'routerStore', 'AuthStore')
@observer
class AddDevicePage extends React.Component<IProps, Istate> {
    state: Istate = {
        device: {
            name: undefined,
            description: undefined,
            entity_type: undefined,
            attributes: [],
            location: { lat: 38.880278, lng: -6.975278 }
        },
        deviceTypes: []
    }

    componentDidMount() {
        this.getDeviceTypes();
        this.getLocation();
    }

    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                this.setState({ device: { ...this.state.device, location: { lat: latitude, lng: longitude } } })
            },
                (error: any) => {
                    console.log(error)
                });
        } else {
            console.log("Geolocation is not supported by this browser.")
        }
    }

    async getDeviceTypes() {
        const deviceTypes: Array<IDeviceType> | undefined = await this.props.DevicesStore.getDeviceTypesByUser()
        this.setState({ deviceTypes: deviceTypes ? deviceTypes : [] })
    }

    onSubmit = async (values: IDeviceCreation, actions: FormikActions<IDeviceCreation>) => {
        actions.setSubmitting(true);

        let device: any = { ...values };

        try {
            if (device.attributes && device.attributes.length > 0)
                device.attributes = device.attributes.map((attributeId?: string) => this.state.attributes!.find((d: string | Attribute) => (d as Attribute).object_id === attributeId))

            const newDevice = await this.props.DevicesStore.createDevice(device);
            await this.handleAddLocation(newDevice.entity_name!, device);
            message.success(this.props.t('success.created_device'))
            this.props.routerStore.goBack();
        } catch (error) {
            message.error(this.props.t('errors.created_device'))
        }
        actions.setSubmitting(false);
    }

    async handleAddLocation(id: string, device: IDeviceCreation) {
        const newAttributes: any = {
            latitude: device.location!.lat,
            longitude: device.location!.lng,
            name: device.name,
            description: device.description
        }
        await this.props.DevicesStore.updateEntityAttributes(id!, newAttributes, { options: 'keyValues' })
    }

    handleCloseModal() {
        Modal.destroyAll()
    }

    handleAddEntityType = () => {
        const { DevicesStore, AuthStore, routerStore, t } = this.props;
        Modal.confirm({
            title: t('add_device_profile'),
            content: <AddDeviceType DevicesStore={DevicesStore} AuthStore={AuthStore} routerStore={routerStore}
                closeModal={(getTypes?: boolean) => {
                    if (getTypes) {
                        this.getDeviceTypes();
                    }
                    this.handleCloseModal()
                }} />,
            mask: true,
            maskClosable: true,
            width: '60vw',
            className: 'hide-modal-buttons'
        })
    }

    handleSelectType = async (key: string, value: any, setFieldValue: (key: string, value: any) => void) => {
        const entityType: IDeviceType | undefined = this.state.deviceTypes.find(deviceType => deviceType.entity_type === value);

        if (entityType) {
            await this.setState({
                attributes: entityType.attributes
                    .filter((d: any) => !['lat', 'lon', 'name', 'description'].includes(d.object_id!))
            });

        }
        setFieldValue(key, value);
        setFieldValue('attributes', [])
    }

    render() {
        const { t, routerStore } = this.props;
        const { deviceTypes, device, attributes } = this.state;

        return (
            <Card className='body-content' title={
                <PageHeader onBack={() => routerStore.goBack()} title={t('add_device')} style={{ padding: 0 }} />
            }>
                <Formik enableReinitialize
                    initialValues={device}
                    onSubmit={this.onSubmit}
                    validationSchema={yup.object().shape({
                        name: yup.string().required(t('validation_scheme.required_entity_name')),
                        entity_type: yup.string().required(t('validation_scheme.required_entity_type')),
                    })}
                    render={({ isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <Row gutter={24}>
                                <Col xs={24} sm={12}>
                                    <AntForm.Item label={t('entity_name')} required >
                                        <Field name="name" render={({ field }: FieldProps) => (
                                            <Input {...field} placeholder={t('entity_name')} />
                                        )} />
                                        <InputError title='name' />
                                    </AntForm.Item>
                                    <AntForm.Item label={t('entity_description')} >
                                        <Field name="description" render={({ field }: FieldProps) => (
                                            <Input {...field} placeholder={t('weather_station')} />
                                        )} />
                                    </AntForm.Item>

                                    <AntForm.Item label={t('device_profile')} required>
                                        <Field name="entity_type" render={({ field }: FieldProps) => (
                                            <div
                                                onMouseDown={e => {
                                                    e.preventDefault();
                                                    return false;
                                                }}
                                            >
                                                <Select onChange={value => this.handleSelectType('entity_type', value, setFieldValue)}
                                                    placeholder={t('device_profile')}
                                                    dropdownRender={menu => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <Button icon='plus' type='link' block onClick={() => this.handleAddEntityType()}>{t('add_device_profile')}</Button>
                                                        </div>
                                                    )}
                                                >
                                                    {
                                                        deviceTypes.map(deviceType =>
                                                            <Select.Option key={deviceType.entity_type}>{deviceType.entity_type}</Select.Option>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                        )} />
                                        <InputError title='entity_type' />
                                    </AntForm.Item>
                                    {
                                        attributes && <AntForm.Item label={t('reading')}>
                                            <Field name="attributes" render={({ field }: FieldProps) => (
                                                <Select mode={'multiple'} placeholder={t('reading')} value={values.attributes}
                                                    onChange={(value: any) => setFieldValue('attributes', value)}
                                                >
                                                    {
                                                        attributes.map((attribute, index) => {
                                                            attribute = (attribute as Attribute);
                                                            const { t, i18n } = this.props;
                                                            let name: string | undefined = attribute.name!;
                                                            let unit: string = '';

                                                            const endpointDescription: IEndpointsDescription | undefined = ENDPOINTS_DESCRIPTION.find(e => e.fiware === name!);
                                                            if (endpointDescription) {
                                                                name = endpointDescription.description[i18n.language];
                                                                if (endpointDescription.unit && endpointDescription.unitDescription)
                                                                    unit = `${t('in')} ${endpointDescription.unitDescription[i18n.language]}`;
                                                            }

                                                            return (
                                                                <Select.Option key={attribute.object_id}>
                                                                    {`${name} ${unit}`}
                                                                </Select.Option>
                                                            )
                                                            // return (<Select.Option key={(attribute as Attribute).object_id || index.toString()}
                                                            //     disabled={['lat', 'lon'].includes((attribute as Attribute).object_id!)}>
                                                            //     {(attribute as Attribute).name}
                                                            // </Select.Option>)
                                                        }
                                                        )
                                                    }
                                                </Select>
                                            )} />
                                        </AntForm.Item>
                                    }
                                </Col>
                                <Col xs={24} sm={12}>
                                    <AntForm.Item label={t('add_location_title')}>
                                        <LocationMap position={values.location} setFieldValue={setFieldValue} />
                                    </AntForm.Item>
                                </Col>
                                <div className="text-center">
                                    <Button onClick={() => routerStore.goBack()}>{t('cancel')}</Button>
                                    <Button type="primary" style={{ margin: '0 1em' }}
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                        htmlType="submit">{t('save')}</Button>
                                </div>
                            </Row>
                        </Form>
                    )}
                />
            </Card>
        )
    }
}

export default withTranslation()<IProps>(AddDevicePage)
