import React, { ReactText } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Form as AntForm, Input, Card, Table, Button, Col, Row, Popconfirm, notification, Select, Icon } from 'antd'
import { inject, observer } from "mobx-react";
import DevicesStore from "../stores/DevicesStore";
import { Formik, Form, Field, FieldProps, FormikActions } from 'formik';
import * as yup from 'yup';
import { ColumnProps } from "antd/lib/table";
import { IEndpoint } from "../models";

type IProps = WithTranslation & {
    DevicesStore?: DevicesStore
}

type IState = {
    endpoint: IEndpoint;
    endpoints: Array<IEndpoint>,
    adding: boolean,
    searchText: ReactText
}

@inject('DevicesStore')
@observer
class Endpoints extends React.Component<IProps, IState> {
    searchInput: Input | undefined | null;
    state: IState = {
        endpoint: {},
        endpoints: [],
        adding: false,
        searchText: ''
    }

    componentDidMount() {
        this.getEndpoints();
    }

    async getEndpoints() {
        let result = await this.props.DevicesStore!.getEndpoints();
        this.setState({ endpoints: result })
    }

    async onDelete(endpoint: IEndpoint) {
        try {
            await this.props.DevicesStore!.deleteEndpoint(endpoint._id!)
            await this.getEndpoints()
            this.showSuccess()
        }
        catch (err) {
            this.showError()
        }
    }

    showSuccess() {
        notification.success({ message: 'Success', duration: 1, })
    }

    showError() {
        notification.error({ duration: 1, message: 'Error' })
    }

    onCreateEndpoint = async (values: IEndpoint, actions: FormikActions<IEndpoint>) => {
        actions.setSubmitting(true);
        try {
            await this.props.DevicesStore!.addEndpoint(values);
            await this.getEndpoints()
            actions.setSubmitting(false)
            this.setState({ adding: false })
        }
        catch (err) {
            actions.setSubmitting(false)
        }
    }

    getColumnSearchProps = (dataIndex: string): ColumnProps<IEndpoint> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => this.searchInput = node}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys && selectedKeys[0]}
                    onChange={e => setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button type="primary" icon="search" size="small"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 90, marginRight: 8 }}
                >
                    {'Search'}
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    {'Reset'}
                </Button>
            </div>
        ),
        filterIcon: filtered => (<Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />),
        onFilter: (value, record: any) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => visible && setTimeout(() => this.searchInput!.select())
    });

    handleSearch(selectedKeys: ReactText[] | undefined, confirm: (() => void) | undefined) {
        confirm && confirm();
        this.setState({ searchText: selectedKeys ? selectedKeys[0] : '' });
    }

    handleReset(clearFilters: ((selectedKeys: string[]) => void) | undefined) {
        clearFilters && clearFilters([]);
        this.setState({ searchText: '' });
    }

    render() {
        const { t } = this.props
        const { endpoints, adding, endpoint } = this.state

        const columns: Array<ColumnProps<IEndpoint>> = [
            {
                title: 'ID', dataIndex: 'object_id', key: 'object_id', ...this.getColumnSearchProps('object_id')
            }, {
                title: t('name'), dataIndex: 'name', key: 'name', ...this.getColumnSearchProps('name')
            }, {
                title: t('unit'), dataIndex: 'unit', key: 'unit'
            }, {
                title: t('type'), dataIndex: 'type', key: 'type'
            }, {
                title: t('actions'),
                dataIndex: 'actions',
                key: 'actions',
                render: (text: any, endpoint: IEndpoint) => (
                    <div>
                        <Popconfirm
                            title={t('popconfirm.title')}
                            onConfirm={() => this.onDelete(endpoint)}
                            onCancel={() => { }}
                            okText={t('popconfirm.yes')}
                            cancelText={t('popconfirm.no')}
                        >
                            <Button type="link">{t('delete')}</Button>
                        </Popconfirm>
                    </div>
                )
            }
        ];


        return (
            <Card className='body-content' title={t('supported_readings')}>
                <div className='description-page'>
                    <div>{t('supported_readings_title')}</div>
                    <Button disabled={adding} onClick={() => this.setState({ adding: true })}>{t('add_supported_reading')}</Button>
                </div>
                {adding ?
                    <Formik enableReinitialize
                        initialValues={endpoint}
                        onSubmit={this.onCreateEndpoint}
                        validationSchema={yup.object().shape({
                            name: yup.string().required(t('validation_scheme.required_name')),
                            type: yup.string().required(t('validation_scheme.required_type')),
                            object_id: yup.string().required(t('validation_scheme.required_id'))
                        })}
                        render={({ isSubmitting, setFieldValue }) => (
                            <Form>
                                <Row gutter={24} type='flex' align='middle' justify='center'>
                                    <Col xs={24} sm={12}>
                                        <AntForm.Item label='ID' required>
                                            <Field name="object_id" render={({ field }: FieldProps) => (
                                                <Input {...field} placeholder={t('supported_reading_id_placeholder')} />
                                            )} />
                                        </AntForm.Item>
                                        <AntForm.Item label={t('name')} required>
                                            <Field name="name" render={({ field }: FieldProps) => (
                                                <Input {...field} placeholder={t('supported_reading_name_placeholder')} />
                                            )} />
                                        </AntForm.Item>
                                        <AntForm.Item label={t('type')} required>
                                            <Field name="type" render={({ field }: FieldProps) => (
                                                <Select {...field} placeholder={t('supported_reading_type_placeholder')}
                                                    onChange={(value: string) => setFieldValue('type', value)}>
                                                    <Select.Option value='array'>Array</Select.Option>
                                                    <Select.Option value='boolean'>Boolean</Select.Option>
                                                    <Select.Option value='text'>Text</Select.Option>
                                                    <Select.Option value='number'>Number</Select.Option>
                                                    <Select.Option value='structuredValue'>Object</Select.Option>
                                                </Select>
                                            )} />
                                        </AntForm.Item>
                                        <AntForm.Item label={t('unit')}>
                                            <Field name="unit" render={({ field }: FieldProps) => (
                                                <Input {...field} placeholder={t('supported_reading_unit_placeholder')} />
                                            )} />
                                        </AntForm.Item>
                                    </Col>
                                </Row>
                                <div className="text-center">
                                    <Button onClick={() => this.setState({ adding: false })}> {t('cancel')}</Button>
                                    <Button type="primary" style={{ margin: '0 1em' }}
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                        htmlType="submit">{t('save')}</Button>
                                </div>
                            </Form>
                        )}
                    />
                    :
                    <Table rowKey={(row: IEndpoint, index: number) => row._id || index.toString()}
                        dataSource={endpoints}
                        columns={columns}
                        pagination={{ size: 'small' }} />
                }
            </Card>
        )
    }

}

export default withTranslation()(Endpoints)