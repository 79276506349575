import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Drawer, Spin, Button, Collapse, Row, Col, notification, Icon } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import Clipboard from 'react-clipboard.js';

import DashboardStore from '../stores/DashboardStore';
import EntitiesStore from '../stores/EntitiesStore';
import { ChartContainer } from '../components';
import { getNameById, parseDateSidebar } from '../utils';
import { IEndpointsDescription, ENDPOINTS_DESCRIPTION } from '../utils/constants';

type IProps = WithTranslation & {
  DashboardStore?: DashboardStore;
  EntitiesStore?: EntitiesStore;
};

@inject('DashboardStore', 'EntitiesStore')
@observer
class SidebarDevice extends Component<IProps> {
  state = {
    pollutant: '',
  };

  onChangePanel = (e: any) => {
    this.setState({ pollutant: e, value: 'e', copied: false });
  };

  isNoValue = (value: string) => {
    if (typeof value === 'undefined') return true;
    else return false;
  };

  /**
   * Endpoint is not allowed to be displayed
   * 
   * @param key : parameter key
   */
  isNotAllowed = (key: string) => {
    if (['federate', 'config'].includes(key))
      return true;

    return false;
  }

  handleNotification = () => {
    const { t } = this.props;
    notification.open({
      message: t('api_clipboard'),
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };

  handleInformation = (key: string) => {
    const { t } = this.props;
    const keys = ['NO', 'NO2', 'CO', 'O3', 'PM10', 'PM2.5', 'PM4.0', 'PM1.0', 'SO2', 'airQualityIndex'];
    const index = keys.findIndex(item => item === key);
    if (index === -1) return;
    notification.open({
      message: t('info'),
      description: t(key === 'PM10' ? 'PM100' : key.split('.').join('')),
    });
  };

  render() {
    const { t } = this.props;
    const { pollutant } = this.state;
    const { showSidebar, setshowSidebar } = this.props.DashboardStore!;
    const { selectedEntity, isLoading } = this.props.EntitiesStore!;
    const keys = ['NO', 'NO2', 'CO', 'O3', 'PM10', 'PM2.5', 'PM4.0', 'PM1.0', 'SO2', 'airQualityIndex'];
    // const index = keys.findIndex(item => item === key);
    // if (index === -1) return;

    return (
      <Drawer
        visible={showSidebar}
        onClose={() => setshowSidebar(false)}
        mask={false}
        getContainer={false}
        width={'45vh'}
        closable={false}
        style={{ position: 'absolute' }}
      >
        <Spin spinning={isLoading} style={{ height: '100%' }}>
          <div className='header-sidebar'>
            <div style={{ maxWidth: '70%', fontWeight: 600, paddingBottom: '1em' }}>
              {selectedEntity!.name ? selectedEntity!.name : t(getNameById(selectedEntity!.id))}
            </div>
            <Clipboard
              data-clipboard-text={`http://calidadmedioambiental.org/api/v1/data/${selectedEntity!.id}/${new Date(
                new Date().getTime() - 60 * 60 * 24 * 1000,
              ).toISOString()}/${new Date().toISOString()}/json`}
              className='apiButton'
              onSuccess={() => this.handleNotification()}
            >
              {t('api_copy')}
            </Clipboard>
            <Button onClick={() => setshowSidebar(false)} icon='close' type='link' style={{ color: 'grey' }} />
          </div>
          <p style={{ paddingTop: '0.4em', textAlign: 'left', fontWeight: 300, fontSize: '0.8em' }}>
            {selectedEntity!.id}
            <br />
            {selectedEntity.id
              ? `Lat: ${selectedEntity!.latitude}, Lng: ${selectedEntity!.longitude} - ${selectedEntity!.location}`
              : t('not_found_device')}
          </p>
          {selectedEntity.attributes && (
            <Collapse
              accordion
              expandIconPosition='right'
              destroyInactivePanel
              bordered={false}
              style={{ marginTop: '1em' }}
              onChange={this.onChangePanel}
            >
              {selectedEntity.attributes.map((attribute: any) => {
                const endpointDescription: IEndpointsDescription | undefined = ENDPOINTS_DESCRIPTION.find(
                  e => e.fiware === attribute.key,
                );
                console.log(attribute.name);
                return (this.isNoValue(attribute.value) || this.isNotAllowed(attribute.key)) ? null : (
                  <Collapse.Panel
                    style={{
                      background: '#f7f7f7',
                      borderRadius: 4,
                      marginBottom: 24,
                      border: 0,
                      overflow: 'hidden',
                    }}
                    key={attribute.key}
                    className='panel-header'
                    header={
                      <div>
                        <div style={{ paddingBottom: '.5em' }}>
                          <span style={{ fontWeight: 100 }}>
                            {endpointDescription
                              ? endpointDescription.description[this.props.i18n.language]
                              : attribute.key}
                          </span>
                          <span style={{ paddingLeft: '.5em' }} onClick={() => this.handleInformation(attribute.key)}>
                            {keys.findIndex(item => item === attribute.key) !== -1 ? <Icon type='info-circle' /> : ''}
                          </span>
                        </div>
                        <Row>
                          <Col span={12} style={{ fontWeight: 600, fontSize: '1.2em' }}>
                            {attribute.value}
                            <span style={{ color: 'rgba(115, 114, 114, 0.81)' }}>
                              &nbsp;{endpointDescription && endpointDescription.unit}
                            </span>
                          </Col>
                          <Col style={{ textAlign: 'right' }} span={12}>
                            {this.isNoValue(attribute.value) ? t('no_measure') : parseDateSidebar(attribute.date)}
                          </Col>
                        </Row>
                      </div>
                    }
                  >
                    <ChartContainer deviceId={selectedEntity.id} pollutant={pollutant} />
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          )}
        </Spin>
      </Drawer>
    );
  }
}

export default withTranslation()(SidebarDevice);
