import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouterStore } from 'mobx-react-router';
import { inject, observer } from 'mobx-react';
import { Card, PageHeader, Button, Form as AntForm, Input, message, Icon, Spin, Modal } from 'antd';
import { ColProps } from 'antd/lib/grid/col';
import { Formik, Field, FormikActions, FieldProps } from 'formik';
import * as yup from 'yup';

import { User } from '../../models';
import AuthStore from '../../stores/AuthStore';

import { InputError } from '../../components';
import ChangePaswordForm from './ChangePaswordForm';

type IProps = WithTranslation & {
  AuthStore: AuthStore;
  routerStore: RouterStore;
};

@inject('AuthStore', 'routerStore')
@observer
class ProfilePage extends React.Component<IProps> {
  componentDidMount() {
    this.props.AuthStore.getMe();
  }

  editInfo = async (values: User, actions: FormikActions<User>) => {
    await actions.setSubmitting(true);
    try {
      await this.props.AuthStore.editMe(values);
      message.success(this.props.t('success.edit_user'));
    } catch (error) {
      message.error(this.props.t('errors.edit_user'));
    }
    await actions.setSubmitting(false);
  };

  changePassword() {
    const { AuthStore, t } = this.props;
    const { changePassword } = AuthStore;
    const modalConfirm = Modal.confirm({});

    modalConfirm.update({
      title: t('reset_password'),
      content: (
        <ChangePaswordForm onCancel={modalConfirm.destroy} changePassword={changePassword} />
      ),
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      maskClosable: true,
    });
  }

  handleOnCancel() {
    this.setState({ addDevice: false });
  }

  handleDeleteMyUser = () => {
    Modal.confirm({
      title: 'Esta seguro de eliminar su cuenta',
      content: 'Si desea volver a abrirla deberá ponerse en contacto con un administrador.',
      onOk: async () => {
        await this.props.AuthStore.deleteAccount();
        this.props.routerStore.replace('/dashboard');
      },
    });
  };

  render() {
    const { AuthStore, routerStore, t } = this.props;
    const { user } = AuthStore;

    const validationSchema = {
      email: yup
        .string()
        .email(t('validation_scheme.invalid_email'))
        .required(t('validation_scheme.required_email')),
      name: yup.string().required(t('validation_scheme.required_name')),
      surname: yup.string().required(t('validation_scheme.required_surname')),
    };

    const formItemLayout: { wrapperCol: ColProps; labelCol: ColProps } = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
    };

    const tailFormItemLayout: { wrapperCol: ColProps } = {
      wrapperCol: {
        xs: { span: 24 },
        sm: {
          span: 12,
          offset: 6,
        },
      },
    };

    return (
      <Card
        className="body-content"
        title={
          <PageHeader
            onBack={() => routerStore.goBack()}
            title={t('profile')}
            style={{ padding: 0 }}
          >
            <div className="profile-page-description">
              {user && (
                <div className="user-info">
                  <div className="name">
                    {user!.name} {user!.surname}
                  </div>
                  <div>
                    <span className="email">{user!.email}</span> -
                    <span className="role">&nbsp;{user!.role}</span>
                  </div>
                  <div>
                    <span className="role">
                      {t('fwname')} - /{user!.fw_name}
                    </span>
                  </div>
                </div>
              )}
              <div className="actions">
                <Button
                  disabled={AuthStore.isLoading}
                  type="danger"
                  onClick={this.handleDeleteMyUser}
                  style={{ marginRight: '1em', marginBottom: '.5em' }}
                >
                  {t('delete_account')}
                </Button>
                <Button
                  disabled={AuthStore.isLoading}
                  type="primary"
                  onClick={() => this.changePassword()}
                >
                  {t('change_password')}
                </Button>
              </div>
            </div>
          </PageHeader>
        }
      >
        <Spin spinning={AuthStore.isLoading || !user}>
          <Formik
            enableReinitialize
            initialValues={user!}
            validationSchema={yup.object().shape(validationSchema)}
            onSubmit={this.editInfo}
            render={({ isSubmitting, handleSubmit }) => (
              <AntForm onSubmit={handleSubmit} {...formItemLayout}>
                <AntForm.Item label={t('email')} required>
                  <Field
                    name="email"
                    render={({ field }: FieldProps) => (
                      <Input
                        {...field}
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('placeholder_email_login')}
                      />
                    )}
                  />
                  <InputError title="email" />
                </AntForm.Item>
                <AntForm.Item label={t('name')} required>
                  <Field
                    name="name"
                    render={({ field }: FieldProps) => (
                      <Input {...field} placeholder={t('placeholder_name')} />
                    )}
                  />
                  <InputError title="name" />
                </AntForm.Item>
                <AntForm.Item label={t('surname')} required>
                  <Field
                    name="surname"
                    render={({ field }: FieldProps) => (
                      <Input {...field} placeholder={t('placeholder_surname')} />
                    )}
                  />
                  <InputError title="surname" />
                </AntForm.Item>
                <AntForm.Item {...tailFormItemLayout}>
                  <Button
                    block
                    type="default"
                    htmlType="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    className="btn-round"
                  >
                    {t('save')}
                  </Button>
                </AntForm.Item>
              </AntForm>
            )}
          />
        </Spin>
      </Card>
    );
  }
}

export default withTranslation()(ProfilePage);
