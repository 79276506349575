import { Attribute } from '../models';

export type IPaginationProps<Model = any> = {
  docs: Array<Model>;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  limit?: number;
  nextPage?: number | null;
  page?: number;
  pagingCounter?: number;
  prevPage?: number | null;
  totalDocs?: number;
  totalPages?: number;
};

export type IPaginationParams = {
  page?: number;
  limit?: number;
  attributes?: string | Array<string>;
  sort?: string | undefined;
  search?: string;
};

export type IDeviceType = {
  readonly _id?: string;
  entity_type?: string;
  apikey?: string;
  resource?: string;
  service?: string;
  subservice?: string;
  type?: string;
  attributes: Array<Attribute | string>;
};

export function getUnitByPollutant(pollutant: string) {
  switch (pollutant) {
    case 'PM1':
    case 'PM2':
    case 'PM4':
    case 'PM10':
    case 'NO2':
      return 'μg/m³';
    case 'humidity':
      return '%';
    case 'precipitation':
      return 'mm';
    case 'temperature':
      return '°C';
    case 'pressure':
      return 'Pa';
    case 'windSpeed':
      return 'm/s';
  }
}

export function parseDateSidebar(date: any) {
  let d = new Date(date);
  let day = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  let hour = d.getHours().toString();
  let minute = d.getMinutes().toString();

  let parsedHour = hour.length > 1 ? hour : `0${hour}`;
  let parsedMinute = minute.length > 1 ? minute : `0${minute}`;

  let parsed = `${day}/${month}/${year} ${parsedHour}:${parsedMinute}`;

  return parsed;
}

export function getNameById(id: string): string {
  if (!id) return '';
  let start = id.split(':');

  switch (start[0]) {
    case 'AEMET_POLLUTION':
      return 'aemet_pollution';
    case 'AEMET_OBSERVATION':
      return 'aemet_observation';
    case 'EEA_POLLUTION':
      return 'eea_pollution';
    case 'RESPIRA':
      return 'respira';
    default:
      return id;
  }
}

export type IQueryEntityParams = {
  idPattern?: string;
  typePattern?: string;
  q?: string;
  limit?: number;
  offset?: number;
  attrs?: string;
  orderBy?: string;
  options?: 'count' | 'keyValues';
};

export function removeFromArray(myArray: any, toRemove: any) {
  for (var i = myArray.length - 1; i >= 0; i--) {
    for (var j = 0; j < toRemove.length; j++) {
      if (myArray[i] && myArray[i].id === toRemove[j].id) {
        myArray.splice(i, 1);
      }
    }
  }
  return myArray;
}
