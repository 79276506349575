import React from 'react';
import { Router } from 'react-router-dom';
import { History, createBrowserHistory } from "history";
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';

import Routes from './routes';
import stores from './stores';

import axiosInterceptor from "./utils/axiosInterceptor";

import { useTranslation } from "react-i18next";

import moment from 'moment';

import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/en-gb';

import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale-provider';

import es_ES from 'antd/lib/locale-provider/es_ES';
import pt_PT from 'antd/lib/locale-provider/pt_PT';
import en_GB from 'antd/lib/locale-provider/en_GB';

const browserHistory: History = createBrowserHistory();
const routerStore: RouterStore = new RouterStore();

const allStores = {
  routerStore,
  ...stores
};

axiosInterceptor(browserHistory, stores.AuthStore.logout);

const history = syncHistoryWithStore(browserHistory, routerStore);

const App: React.FC = () => {
  const { i18n } = useTranslation();

  function getLocale(lng?: string): Locale {
    switch (lng) {
      case 'es':
        moment.locale('es')
        return es_ES;
      case 'pt':
        moment.locale('pt')
        return pt_PT;
      case 'en':
      default:
        moment.locale('en')
        return en_GB;
    }
  }

  return (
    <Provider {...allStores}>
      <ConfigProvider locale={getLocale(i18n.language)}>
        <Router history={history}>
          <Routes />
        </Router>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
