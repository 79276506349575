import React from "react";
import { inject, observer } from "mobx-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import { Form as AntForm, Input, Button, message, Icon, Checkbox } from 'antd';
import { Formik, Form, Field, FormikActions, FieldProps } from 'formik';
import * as yup from 'yup';

import { InputError } from ".";

import AuthStore, { ILoginForm } from "../stores/AuthStore";

interface IProps extends WithTranslation {
    AuthStore?: AuthStore,
    closeModal: () => void
}

interface IState {
    initialValues: ILoginForm
}


@inject('AuthStore')
@observer
class LoginForm extends React.Component<IProps, IState> {
    validationSchema: any;
    
    constructor(props: IProps) {
        super(props);

        this.state = {
            initialValues: { email: '', password: '', rememberMe: true }
        }
        this.validationSchema = {
            email: yup.string()
                .email(props.t('validation_scheme.invalid_email'))
                .required(props.t('validation_scheme.required_email')),
            password: yup.string()
                .required(props.t('validation_scheme.required_password'))
        }
    }

    /**
     * Close component
     */
    closeAll = () => {        
        this.props.closeModal();
    }

    submitLogin = async (values: ILoginForm, actions: FormikActions<ILoginForm>) => {
        await actions.setSubmitting(true);
        try {
            await this.props.AuthStore!.login(values)
        } catch (error) {
            message.error(this.props.t('errors.login'));
        }
        await actions.setSubmitting(false);
    }

    render() {
        const { initialValues } = this.state;
        const { t } = this.props

        return (
            <Formik enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object().shape(this.validationSchema)}
                onSubmit={this.submitLogin}
                render={({ values, setFieldValue, isSubmitting }) => (
                    <Form>
                        <AntForm.Item label={t('email')} required className='white-text'>
                            <Field name="email" render={({ field }: FieldProps) => (
                                <Input {...field}
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder={t('placeholder_email_login')} />
                            )} />
                            <InputError title='email' />
                        </AntForm.Item>
                        <AntForm.Item label={t('password')} required className='white-text'>
                            <Field name="password" render={({ field }: FieldProps) => (
                                <Input {...field}
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder={t('placeholder_password_login')} type={'password'}
                                    autoComplete='password' />
                            )} />
                            <InputError title='password' />
                            <Link onClick={this.closeAll} to='reset-password' style={{ color: '#03ecfc' }}>
                                {t('forgot_password')}
                            </Link>
                        </AntForm.Item>
                        <AntForm.Item className='white-text'>
                            <Field name="check" type="checkbox"
                                render={({ field }: FieldProps) => (
                                    <Checkbox  {...field} checked={values.rememberMe}
                                        onChange={(field) => setFieldValue('rememberMe', field.target.checked)}>
                                        <span style={{ fontWeight: 100 }}> {t('remember_me')}</span>
                                    </Checkbox>
                                )} />
                        </AntForm.Item>
                        <AntForm.Item>
                            <Button block type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}
                                style={{ borderRadius: '1em' }}>
                                {t('login')}
                            </Button>
                        </AntForm.Item>
                    </ Form >
                )}
            />
        )
    }
}

export default withTranslation()(LoginForm);